.main-template {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: visible;
  &__header-wrapper {
    max-height: 40vh;
  }
  &__main-wrapper {
    background-color: white;
    width: 100%;
    border-radius: 32px 32px 0 0;
    overflow: auto;
    padding: $spaceMedium;
  }
}
