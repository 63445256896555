@import '../../../../styles/mixins';

.heading {
  margin-bottom: 48px !important;
  text-align: center;
  margin-top: 0 !important;
  @include for_breakpoint(desktop) {
    margin-top: 72px !important;
  }
}

.button {
  margin: 0 auto;
  width: 100%;
}
