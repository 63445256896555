@import 'variables';
.preRegister {
  height: 100%;
  display: flex;
  flex-direction: column;

  .innerContainer {
    margin: $spaceMedium;
  }
  .header {
    margin: 0;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $spaceLarge1;
    width: 100%;
    margin-bottom: 8px;
    p {
      margin: 0;
    }

    .specialColor {
      font-weight: bold;
      color: $adeccoRedSecond;
    }

    .specialColor a {
      font-weight: bold;
      color: $adeccoRedSecond;
      text-decoration: none;
    }

    .ordinarColor a {
      color: $black;
    }
  }
  .secondaryHeader {
    margin: 0;
    font-family: $roboto;
    font-size: $textSizeBody;
    font-style: normal;
    font-weight: 500;
    color: $skyBlue;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 0.28px;
  }
  .tertiaryHeader {
    font-family: $roboto;
    font-size: $textSizeSubtitleSecondary;
    font-weight: 400;
    margin-bottom: 32px;
    line-height: $textSizeTitle;
  }
  .generalButton {
    border-radius: $borderRadiusMedium;
    padding: $spaceMedium;
    background-color: $adeccodGrayLight;
    border: solid;
    color: $white;
    margin: $spaceLarge1 auto $spaceSmall;
    width: 100%;
    font-size: $textSizeSubtitleSecondary;
    text-align: center;
    display: inline-block;
  }

  .activeButton {
    background: $adeccoRedSecond;
  }
  .textInput {
    padding: $spaceMedium;
    border-radius: $borderRadiusSmall;
    width: 100%;
    border: 1px solid $darkGray;
  }
  .primaryHeader {
    font-size: $textSizeTitle;
    font-family: $roboto;
    line-height: 32px;
    font-style: normal;
  }
  .checkBox {
    .MuiIconButton-label {
      color: $adeccoTwilight;
      border-color: $borderRadiusMedium;
    }
  }

  .consent-label {
    & > p {
      color: $skyBlue;
    }
  }

  .consent-link-label {
    & > p {
      color: $skyBlue;
    }
  }
  & a {
    color: $skyBlue !important;
    text-decoration: none;
    text-transform: uppercase;
    font-size: $textSizeBody;
    font-style: normal;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  ul li::marker {
    color: $skyBlue;
  }
}
