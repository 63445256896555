

.RankingSurveyButtonGroup {
    width: 100%;
    padding-left: 5px;
  }
  
  .RankingSurveyButton {
    border-radius: 0 !important;
    border: 1px solid #1976d2;
    white-space: break-spaces;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1C304B;
  
    &:global(.Mui-selected) {
      border: 4px solid #009ACC !important;
      border-radius: 4px !important;
      background-color: #FFFFFF;
    }
  }