@import "variables";

.MuiStepper-vertical {
  &.MuiStepper-vertical.app-vertical-stepper {
    padding: 0;
    & .app-vertical-stepper__step-name {
      font-weight: bold;
    }
    & .app-vertical-stepper__status-text {
      line-height: 26px;
    }
    & .app-vertical-stepper__time-counter-box {
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      &__time-left {
        z-index: 1;
        svg {
          circle {
            stroke-linecap: round;
            stroke-width: 3;
          }
        }
      }
    }
    & .MuiStepConnector-vertical {
      padding: 0;
      margin: -16px 32px -16px 0;
      &.Mui-disabled {
        display: none;
      }
      &.MuiStepConnector-active .MuiStepConnector-line {
        border: unset;
      }
    }
    & .MuiStepConnector-line {
      border-left-style: unset;
      border-right-style: solid;
      border-right-width: 1px;
      height: 64px;
    }
  }
  .MuiStepContent-root {
    border-left: unset;
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
.MuiStep-root {
  &.app-vertical-stepper__step {
    position: relative;
    min-height: 80px;
    margin-bottom: 16px;
    &.active-step {
      background-color: $adeccoTwilight;
      border-radius: 18px;
      padding: 16px;
      cursor: pointer;
      & .MuiStepLabel-vertical {
        top: 50%;
        right: 0;
        width: 100%;
        padding-left: 80px;
        position: absolute;
        transform: translateY(-50%);
      }
      & .MuiSvgIcon-root {
        font-size: 1.1rem;
      }
      & .MuiStepLabel-iconContainer {
        right: 24px;
      }
      & .circular-progress-background {
        color: rgba(255, 255, 255, 0.2) !important;
      }
    }
    &.MuiStep-completed {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      margin-bottom: 0;
      min-height: auto;

      & .MuiStepLabel-completed {
        padding: 0 52px 0 24px;
      }
    }
    & .Mui-disabled {
      background-color: $adeccoIce;
      border-radius: 18px;
      padding: 16px;
      min-height: 80px;
      &.MuiStepLabel-vertical {
        top: 50%;
        right: 0;
        width: 100%;
        padding-left: 80px;
        position: absolute;
        transform: translateY(-50%);
      }
      & .MuiTypography-caption {
        color: $adeccoTwilight;
      }
      & .MuiStepLabel-iconContainer {
        display: none;
      }
      & .app-vertical-stepper__status-text {
        font-size: 14px;
        color: $adeccoRoyal;
        font-style: italic;
        line-height: 26px;
      }
    }
    & .MuiStepLabel-vertical {
      position: relative;
      & .MuiStepLabel-iconContainer {
        position: absolute;
        right: 16px;
        padding-right: 0;
      }
      &.Mui-disabled .MuiStepLabel-label {
        color: $black;
      }
    }
    & .MuiStepIcon-completed {
      color: $adeccoRoyal !important;
    }
    & .MuiSvgIcon-root {
      font-size: 2rem;
    }
    & .MuiCircularProgress-root {
      &.circular-progress-background {
        color: rgba(65, 65, 80, 0.3);
        position: absolute;
        z-index: 0;
        & .MuiCircularProgress-circle {
          stroke-dasharray: 0.1, 4.55 !important;
          stroke-linecap: round;
          stroke-width: 3;
        }
      }
    }
    & .MuiStepLabel-label.MuiStepLabel-active {
      color: $white;
    }
    & .MuiTypography-caption {
      font-size: 12px;
      font-family: Nunito, sans-serif;
    }
  }
}
