@import 'variables';

.sub-header-shaded-box {
  width: 100%;
  background-color: $adeccoIce;
  //TODO change name
  &__royal-box {
    background-color: $adeccoRoyal;
  }
  //TODO change name

  &__twilight_box {
    background-color: $adeccoTwilight;
  }

  &__container-lg {
    padding: 56px 36px 27px;
  }

  &__container-md {
    padding: 56px 36px 27px;
    width: 1280px;
  }

  &__container-md-create-job-order-step-3 {
    padding: 56px 0 27px;
    margin: 0 auto;
    max-width: 1280px !important;
  }

  &__select-input {
    .MuiInputBase-input,
    .MuiFormLabel-root {
      font-family: Merriweather, sans-serif;
      font-size: 18px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 1px;
      color: $adeccoTwilight !important;
    }
    .MuiSelect-iconOutlined {
      fill: $adeccoTwilight;
    }
    .MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined {
      .MuiTypography-subtitle2 {
        font-family: Merriweather, sans-serif;
        font-size: 18px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 1px;
        color: #312068 !important;
      }
      svg {
        display: none;
      }
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
      display: none;
    }
    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
      .MuiListItem-button.Mui-selected {
        background-color: transparent;
      }
    }
  }
  &__ready-to-publish-text {
    color: $white;
  }

  &__name-text {
    color: $adeccoDawn;
    padding-top: 35px;
  }
  &__template-name {
    color: $adeccoDawn;
  }

  &__summary-box {
    &__container {
      color: $adeccoPassive;
      padding-bottom: 24px;
    }
    &__twilight-color-text {
      color: $adeccoTwilight;
      display: inline-block;
    }
    .MuiTypography-h3 {
      color: $adeccoTwilight;
    }
  }

  .dropdown-wrapper {
    .MuiMenu-paper {
      min-width: 168px !important;
    }
  }

  &__search-icon-red {
    .MuiSvgIcon-root {
      color: $adeccoRed;
    }
  }

  &__grid-breaks {
    margin-bottom: 41px;
  }

  &_add-new-button {
    width: 88px;
    height: 32px;
    border-radius: 16px;
    border: solid 1px $adeccoDawn;
  }
  //TODO change name
  &__is-going-to-occur-in-california {
    background-color: #f4f3f2;
    border-radius: 8px;
  }

  &__cancel-icon {
    .MuiSvgIcon-root {
      color: $adeccoGray;
    }
  }
  &__add-circle-icon {
    line-height: 1;
    .MuiSvgIcon-root {
      color: $adeccoRoyal;
      margin-right: 8px;
    }
  }

  &__text-input {
    .MuiInputBase-input {
      font-family: Merriweather, sans-serif;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.4;
      letter-spacing: 2px;
      color: $adeccoRoyal !important;
      &::placeholder {
        font-family: Merriweather, sans-serif;
        font-size: 40px;
        font-weight: 900;
        line-height: 1.4;
        letter-spacing: 2px;
        color: $adeccoDawn;
      }
    }
  }

  .MuiFormControl-root {
    background-color: transparent;
    width: 100%;
    .MuiSelect-root {
      background-color: transparent;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    .MuiInputBase-input {
      background-color: transparent;
    }
  }
  .MuiFormControlLabel-root {
    margin-left: 4px;
  }
  .MuiButton-disableElevation:hover {
    box-shadow: none;
  }

  .MuiButton-outlinedPrimary:hover {
    border: solid 2px $adeccoIce;
    opacity: 0.9;
  }
  .MuiButton-disableElevation:hover {
    box-shadow: none;
    border: solid 2px $adeccoIce;
  }
}
