.container {
  width: 90%;
  max-width: 1400px;
  margin: 32px auto 0;
}

.searchComponent {
  margin-bottom: 16px;
  width: 50%;
}

.item {
  border: 1px solid #0000001e;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
}
