.uploadButton {
  display: inline-block;
  width: 100%;
  padding: 8px 24px;
  width: 100%;
  // height: 28px;
  position: relative;
  background-color: rgb(28, 48, 75);
  border-radius: 8px;
  cursor: pointer;
  vertical-align: text-bottom;
  margin-bottom: 24px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    z-index: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0);
  }
  &:hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.uploadButtonLabel {
  display: block;
  margin: 0 auto;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 24px;
  text-align: center;
  cursor: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
  font-weight: 500;
}

.uploadButtonInput {
  display: block;
  opacity: 0.01;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0 none;
  outline: 0 none;
  width: 100%;
  height: 100%;
  // background-color: rgb(28, 48, 75);
  cursor: pointer;
  z-index: 1;
  margin-top: 0;
}

.uploadButtonDisabled {
  background-color: #ced7e4;
  cursor: not-allowed;
}
