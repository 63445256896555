.registration-button {
  background-color: #da291c;
  border-radius: 10px;
  width: 100%;
  margin-left: 0;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  &__text {
    span {
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
    }
  }
}

.label {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #273446;
}

.value-input div {
  margin-top: 5px;
  margin-bottom: 20px;
}

.wrapper {
  padding: 5%;
}

.header {
  font-family: 'HouschkaHead';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 29px;
  color: #273446;
}

.subheader {
  font-family: 'HouschkaHead';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #273446;
  margin-bottom: 0px;
}

.label-required-fields {
  margin-bottom: 20px;
  margin-top: 0px;
}
.accept-terms-wrapper {
  display: flex;
  flex-direction: row;
  &__checkbox {
    padding-left: 0 !important;
    height: 45px;
  }
  &__label {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #273446;
  }
}

.mobile-phone-section {
  display: flex;
  flex-direction: row;
  &__label {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #273446;
  }
}

.prefix-container {
  width: 35%;
  margin-right: 5px;
}

.phone-container {
  width: 60%;
}

.country-dropdown {
  margin-bottom: 35px;
}
