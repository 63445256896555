@import './variables';

.location-employment-type {
  color: $black;

  height: 100%;

  &__header {
    text-align: center;
    padding-bottom: $spaceMedium;
  }
  &__location {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: $spaceMedium;
    border: 1px solid $adeccodGrayLight;
    border-radius: $borderRadiusMedium;
    box-sizing: border-box;
    &-title {
      display: flex;
      align-items: center;
      padding: $spaceSmall;

      & > svg {
        color: $adeccoRedSecond;
      }
    }
    &-body {
      margin-left: 24px;
      padding-bottom: $spaceSmall;
      padding-left: $spaceSmall;
    }
    &-continue {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $adeccodGrayLight2;
      padding: $spaceSmall;
      align-items: center;
      & > svg {
        color: $adeccoRedSecond;
        margin-left: $spaceSmall;
      }
    }
    &-job-type{
      margin-left: 24px;
    }

  }
  .container {
    .descriptionText {
      max-width: min-content;
      min-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }

    .select {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $spaceMedium;
      padding: $spaceSmall;
      border-radius: $spaceSmall;
      border-color: $gray;
      color: $gray;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: $spaceMedium;

      .arrow {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .bottomBoard {
      background: white;
      min-height: 30%;

      .doneButton {
        margin: $spaceSmall;
        font-weight: 500;
      }
    }
  }
  .active {
    background: #c4c4c4;
  }
}
