@import 'variables';

.admin-autocomplete {
  .MuiInputLabel-formControl {
    margin-top: 0;
  }
  .MuiFormLabel-root {
    position: relative;
  }
  &__chip {
    margin-top: 8px;
    background-color: $lightBlue;
    border-radius: 8px;
    font-size: 10px;
    font-weight: bold;
  }
}
