@use 'src/styles/variables' as *;

.adminHeader {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
}

.signatureButton {
  color: $text02;
  font-size: 12px;
}
