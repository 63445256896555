@import '../../../styles/mixins';

.container {
  display: flex;
  height: 100%;
}

.content {
  position: absolute;
  left: 64px;
  right: 64px;
  bottom: 80px;
  background: white;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 48px 64px;
  border-radius: 8px;
  @include for_breakpoint(mobile) {
    bottom: 48px;
    padding: 32px 16px;
    left: 16px;
    right: 16px;
  }
  @include for_breakpoint(desktop) {
    position: relative;
    width: 35%;
    padding: 6vw 3vw;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
  @include for_breakpoint(macbookAir) {
    width: 40%;
  }
}

.branding {
  flex: 1;
  background: linear-gradient(180deg, #da291c 0%, #bf0d3e 100%);
  display: flex;
  position: relative;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  img {
    width: 158px;
  }
  padding-top: 128px;
  @include for_breakpoint(mobile) {
    padding-top: 82px;
  }
  @include for_breakpoint(desktop) {
    padding-left: 64px;
    padding-right: 50px;
    padding-top: 64px;
    text-align: initial;
  }
}

.drawing {
  background-image: url('/assets/adeccobrandmark.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  @include for_breakpoint(desktop) {
    background-position-x: 64px;
  }
}
