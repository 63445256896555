@import 'variables';

.documents-and-data-upload {
  width: 100%;
  &__item {
    &:nth-child(odd) {
      background-color: $adeccoSecondaryGrayLight2;
    }

    &:nth-child(even) {
      background-color: $adeccoSecondaryGrayLight3;
    }

    padding: $spaceLarge1;
  }

  &__status-completed {
    color: $adeccoGreen2;
  }

  &__status-missing {
    color: $adeccoYellow;
  }

  &__status-rejected {
    color: $primaryBrandRed;
  }

  &__category_title {
    margin-top: 1em;
    padding-left: 1em;
    font-weight: bold;
  }
}
