@import '../theme1/variables';
.circular-progress {
  &__component {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: inline-block;

    & > div:first-of-type {
      z-index: 1;
      top: auto;
      left: auto;
      color: $adeccoRedSecond;
      display: block;
      position: absolute;
      svg {
        circle {
          stroke-linecap: round;
        }
      }
    }

    & > div:last-of-type {
      position: relative;
      z-index: 0;
      top: auto;
      color: $lightGray;
      left: auto;

      display: block;
    }
  }

  &__button {
    & > button:first-of-type {
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      margin-bottom: 1rem;
      padding: 0;
      svg {
        path {
          fill: $white;
        }
      }
    }

    .disabled-color {
      color: $lightGray;
    }
  }
}
