.textFieldWithButton__infoDescription__box--lightBackground  {
  .textFieldWithButton {
    &__infoDescription {
      &__box {
        background-color: #F8F8F9 !important;
      }
    }
  }
}

.textFieldWithButton__infoDescription__text--black  {
  .textFieldWithButton {
    &__infoDescription {
      &__text {
        color: #1C304B !important;
      }
    }
  }
}
.coloredBlueText__accordeon{
  color: rgb(16, 123, 158) !important
}
