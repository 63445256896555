.registration-page-email {
  display: flex;
  flex-direction: column;

  &_field {
    margin-top: 12px;
    padding-left: 25px;
    width: 90%;

    &-label {
      font-size: $textSizeSubtitleSecondary;
    }
  }

  &_password {
    margin-top: 20px;
    padding-left: 25px;
    width: 90%;
  }

  &_password-validation {
    margin-top: 30px;
    padding-left: 25px;

    &__label {
      font-size: $textSizeBody;
      font-weight: 600;
    }

    &__upper-case {
      display: flex;
      margin-top: 15px;
      &__icon {
        margin-right: 5px;
      }
      &__label {
        margin-top: 1px;
        font-size: $textSizeBody;
      }
    }

    &__minimum-characters {
      display: flex;
      margin-top: 15px;
      &__icon {
        margin-right: 5px;
      }
      &__label {
        margin-top: 1px;
        font-size: $textSizeBody;
      }
    }

    &__lower-case {
      display: flex;
      margin-top: 15px;
      &__icon {
        margin-right: 5px;
      }
      &__label {
        margin-top: 1px;
        font-size: $textSizeBody;
      }
    }

    &__special-character {
      display: flex;
      margin-top: 15px;
      &__icon {
        margin-right: 5px;
      }
      &__label {
        margin-top: 1px;
        font-size: $textSizeBody;
      }
    }
  }

  &_terms-conditions {
    &__privacy {
      display: flex;
      margin-left: 12px;
      .MuiIconButton-root {
        color: black;
      }

      &-label {
        margin-top: 40px;
      }
    }
    &__terms {
      display: flex;
      margin-left: 12px;
      .MuiIconButton-root {
        color: black;
      }

      &-label {
        margin-top: 20px;
      }
    }
    &__consent {
      display: flex;
      margin-left: 12px;
      .MuiIconButton-root {
        color: black;
      }

      &-label {
        margin-top: 20px;
      }
    }
  }
  &_submit-button {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;

    .MuiButton-root {
      width: 90%;
    }
  }

}