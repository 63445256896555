.pageContainer {
  position: fixed;
  left: 80px;
  right: 0;
  bottom: 0;
  top: 0;
  background: white;
  z-index: 1;
}

.contentContainer {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.preview {
  height: 100%;
  width: 60%;
  margin-right: 32px;
  margin-top: 40px;
  height: calc(100% - 120px);
}

.details {
  flex: 1;
  margin-right: 112px;
  background-color: #f8f8f9;
  margin-top: 72px;
  padding: 40px 32px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
