@import '../theme1/variables';

.outerContainer {
    width:450px;
    margin:50px;
    .innerContainer{
        height:2px;
        background:$adeccoRedSecond;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        max-width:fit-content;
        .mleft{
            margin-left:$spaceLarge1;
        }

        .item{
            width:$spaceMedium;
            height:$spaceMedium;
            background:$adeccoRedSecond;
            border-radius:50%;
            position:relative;
            .image{
                margin-left:3px;
                margin-bottom:5px;
            }
        }

        .lastItem{
            width:$spaceMediumLarge;
            height:$spaceMediumLarge;
            background:$adeccoRedSecond;
            border-radius:50%;
            position:relative;

            -webkit-box-shadow: 0px 0px 5px 2px $adeccoRedSecond;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
            -moz-box-shadow:    0px 0px 5px 2px $adeccoRedSecond;  /* Firefox 3.5 - 3.6 */
            box-shadow:         0px 0px 5px 2px $adeccoRedSecond;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
        }

        .afterItem{
            width:$spaceMedium;
            height:$spaceMedium;
            border-radius:50%;
            border:solid;
            border-color:$adeccoRedSecond;
            background:white;
            border-width:2px;
            position:relative;

        }


    }
}