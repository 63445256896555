@import '../theme1/variables';

.container {
  max-width: 100vw;
  max-height: 100vh;
}

.steps {
  padding: $spaceMedium;

  .headerFont {
    font-size: $textSizeBigTitle;
    color: $primaryDark;
    font-weight: 600;
    font-family: $nunito;
  }

  .secondaryTitle {
    font-size: $textSizeBodyBigger;
    font-family: $nunito;
    font-weight: normal;
  }

  .secondaryHeaderFont {
    font-family: $nunito;
    font-size: $textSizeTitle;
    font-weight: normal;
    color: $primaryDark;
    margin-left: $spaceMedium;
  }

  .circle {
    width: 60px;
    display: flex;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $spaceMedium;
    margin-bottom: $spaceMedium;
  }

  .col {
    display: flex;
    flex-direction: column;
  }
}

.nextButton {
  background: $adeccoRedSecond;
  color: $white;
  border: none;
  font-family: $nunito;
  padding: $spaceMedium;
  border-radius: $borderRadiusMedium;
  letter-spacing: 0.04em;
  width: 100%;
  margin-top: 20px;
}

.nextButton:disabled {
  background: #c7ced6;
}

.errorText {
  color: $adeccoRedSecond;
}

.errorSpain {
  margin: 0;
  font-size: 10px;
  font-style: normal;
  margin-top: 3px;
  text-align: left;
  font-family: Nunito, sans-serif;
  font-weight: bold;
  line-height: 1.29;
  font-stretch: normal;
  letter-spacing: 0.2px;
}

.avalibility_times {
  display: flex;
  flex-direction: row;
}

.contact-info-checkbox {
  color: black !important;
}

.contact-info-checkbox-disabled {
  color: $adeccoIce !important;
}

.checkbox-box {
  width: 50%;
}
.specialColor a {
  font-weight: bold;
  color: $adeccoRedSecond;
  text-decoration: none;
}
.no-text-decoration a {
  text-decoration: none;
}
.buttons-delete-save {
  justify-content: flex-end;
}

.buttons-delete-save button {
  width: auto;
  margin: 20px 20px 20px 0;
}

.worked-for-amazon-box {
  display: flex;
}

.checbox-label {
  margin-top: 10px !important;
}

.addChildButton {
  background: none;
  border-radius: $borderRadiusMedium;
  color: $primaryDark;
  border: solid;
  border-color: $primaryDark;
  font-family: $nunito;
  font-size: $textSizeSubtitle;
  padding: $spaceMedium;
  border-radius: $borderRadiusMedium;
  letter-spacing: 0.04em;
  margin-top: $spaceMedium;
}

.addChildButton:disabled {
  background: $adeccoGray;
}

.childAccordion {
  background: $adeccodGrayLight2 !important;
  border-radius: $borderRadiusMedium !important;
  margin-bottom: $spaceMedium;

  .childOrRelative {
    display: flex;
    flex-direction: row;
  }

  .childAccordionSummary {
    margin-bottom: $spaceMedium;
  }

  .accordionDetails {
    display: block;
  }
}

.rowIT {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $spaceMedium;

  .details {
    border: solid;
    border-radius: $borderRadiusMedium;
    padding: $spaceSmall;
    font-family: $nunito;
    font-weight: 700;
    font-size: $textSizeSubtitle;
  }
}

.personal-details {
  .MuiDialog-paper {
    margin: 0 !important;
  }
}

.TRFDialogItaly {
  min-height: 60vh;

  .MuiDialogContent-root {
    min-height: 60vh;
  }

  .MuiPaper-root {
    margin: 0 !important;
  }

  .MuiDialog-scrollPaper {
    align-items: flex-end !important;
  }

  & .TRFDialogItaly-dialog-title {
    & > h2 {
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: 1.22;
      font-stretch: normal;
      letter-spacing: 1px;
      flex: 1;
      justify-content: space-between;
      display: flex;

      & > span {
        font-size: 1.5em;
        margin-block-start: 1em;
        margin-block-end: 1em;
      }
    }
  }
}

.consent {
  &-link-label,
  &-label,
  &-description {
    p {
      font-family: 'Roboto';
      font-style: normal;
      line-height: 16px;
      display: flex;
      align-items: center;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  &-link-label,
  &-label {
    p {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }

  &-link-label,
  &-mix-label {
    p {
      display: flex;
      flex-wrap: wrap;
      font-family: 'Roboto';
      font-size: 14px;
      letter-spacing: 0.02em;
      // text-transform: uppercase;
      a{
        color: $skyBlue !important
      }
      
    }
  }

  &-label {
    p {
      margin: 0;
    }
  }

  &-description {
    p {
      font-weight: 400;
      margin-top: 8px;
      color: #5c6573;
      letter-spacing: 0.004em;
      font-size: 14px;
    }
  }
}
