@import './../../../../../styles/mixins';
@import './../../../../../styles/_variables.scss';

.candidateInfo {
  grid-column: 8/-1;
  @include for_breakpoint(full-hd) {
    max-width: 528px;
    width: 100%;
  }

  .warningMessage {
    color: #e87a71;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .healthSeverity {
    display: flex;
    justify-content: space-between;
  }

  .healthSeverityRating {
    display: flex;
    font-weight: 500;
    align-items: center;
  }
}

.header {
  position: relative !important;
}

.menu {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
}

.notification {
  width: 600px !important;
}

.witSeparator {
  border-top: 1px $bordersDividers01 solid;
}

.infoList {
  padding: 0 !important;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 !important;
    padding: 8px 0 !important;
  }
  &__itemDescription {
    display: flex;
    align-items: center;
    width: 30%;
    word-break: break-all;
  }
  &__icon {
    font-size: 16px;
    margin-right: 8px;
    white-space: nowrap;
  }
  &__itemContent {
    word-break: break-all;
    width: 65%;
    display: flex;
    justify-content: flex-end;
  }
}
