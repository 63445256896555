.control {
  &__container {
    background-color: white;
    padding-left: 24px;
    padding-right: 24px;
  }
  &__top {
    padding-top: 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &__mid {
    padding-top: 24px;
  }
  &__bot {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 16px;
  }
  &__solo {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 24px;
    border-radius: 8px;
  }
  &--disabled {
    border: 1px solid #ced7e4 !important;
    cursor: not-allowed;
    span {
      color: #ced7e4;
    }
  }
}
