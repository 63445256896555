@import "variables";

.admin-select-button {
  & .MuiListItem-root {
    height: 26px;
    background-color: transparent !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.44px;
  }

  & .MuiButtonGroup-grouped {
    border: solid 1px $primaryDark;
  }
}
