@import 'variables';

.document-button {
  &__main_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px;
    height: 60px;
    border: 2px solid $adeccoSecondaryGray;
    box-sizing: border-box;
    border-radius: 6px;
  }

  &__right_container1 {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  &__right_container2 {
    display: flex;
    align-items: center;
  }

  &__header {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
  }

  &__bold-title {
    font-family: Roboto, Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -1px;
  }

  &__title {
    font-family: Roboto, Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.25px;
  }

  &__status {
    font-family: Roboto, Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.25px;
    margin-right: 22px;

    &--disabled {
      margin-right: 32px;
    }

    &--verified,
    &--signed {
      color: $adeccoGreen;
    }

    &--rejected {
      color: $primaryBrandRed;
    }

    &--review {
      color: $adeccoBlue;
    }

    &--missing {
      color: $adeccoYellow;
    }
  }
}

.document-button-preview {
  &__main_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 60px;
    background-color: #ebebee;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 16px;
  }
  &__container {
    display: flex;
    width: 100%;
    align-items: center;
  }

  &__preview-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    min-height: 56px;
    cursor: pointer;
    background-color: #ebebee;
    border-radius: 8px;
    padding: 8px;
    margin-top: 16px;
  }

  &__title-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    border: 1px solid #1c304b;
    background-color: white;
    border-radius: 8px;
  }

  &__title {
    width: 100%;
    position: relative;
    left: 10px;
    font-family: Roboto, Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.25px;
    color: $primaryBrandRed;
  }

  &__image {
    height: 40px;
    cursor: pointer;
    margin-right: 8px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    width: 40px;
    cursor: pointer;
    margin-left: auto;
    color: #adadb5;
    align-self: baseline;
  }
}

.document-overview {
  &__title {
    font-family: Houschka Head, Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 10px;
  }
}

.document-form {
  padding: $spaceMedium;
  height: 100%;

  &__main-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  &__upload_form_container {
    min-width: 600px;
    margin: 5px;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // margin-bottom: 60px;
    width: 100%;
  }

  &__title {
    margin-bottom: 10px;
    font-family: 'HouschkaHead', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
  }

  &__description {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  &__photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    gap: 20px;
  }

  &__photo-subcontainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
  }

  &__photo_frame {
    border: 4px solid $adeccoPrimaryBlack;
    border-radius: 50%;
    padding: 9px;
  }

  &__photo_cropper {
    width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }

  &__photo_cropper_image {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }

  &__photo_image {
    width: 200px;
  }

  &__photo_black_label {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    cursor: pointer;
  }

  &__photo_red_label {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: $primaryBrandRed;
    text-decoration: none;
  }

  &__warning-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 54px;
    justify-content: center;
  }

  &__warning_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -1px;
  }

  &__size-limit-error {
    text-align: center;
    color: red;
  }

  &__border-down {
    border: solid;
    border-color: rgba(60, 60, 67, 0.29);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 0.5px;
  }
  &__border-up {
    border: 0;
  }
  &__action-container {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
  }
  &__action-button {
    width: 100%;
    font-family: $roboto;
    .MuiButton-label {
      text-transform: none;
    }
    &--red {
      color: $primaryBrandRed;
      border: 1px solid $primaryBrandRed;

      .MuiButton-label {
        text-transform: none;
      }
    }
  }

  &__action-button-special {
    width: 100%;
    border-radius: 0px;
    font-size: 16px;
    font-family: $roboto;
    .MuiButton-label {
      text-transform: none;
      color: #007aff;
      font-weight: normal;
    }
    &--red {
      color: $primaryBrandRed;
      border: 1px solid $primaryBrandRed;

      .MuiButton-label {
        text-transform: none;
      }
    }
  }

  &__action-button-cancel {
    width: 100%;
    border-radius: 0px;
    font-size: 16px;
    font-family: $roboto;
    background: $white;
    border-radius: $borderRadiusMedium;
    .MuiButton-label {
      text-transform: none;
      color: #007aff;
      font-weight: bold;
    }
    &--red {
      color: $primaryBrandRed;
      border: 1px solid $primaryBrandRed;

      .MuiButton-label {
        text-transform: none;
      }
    }
  }

  &__accordion-root {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__accordion-summary {
    background-color: #f1f3f6;
    border: 2px solid #c0c7d0;
    border-radius: 10px;
  }

  &__accordion-details {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
  }

  &__add-page-root {
    display: flex;
    gap: 12px;
    cursor: pointer;
  }

  &__add-page-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
  }
}

.document-preview-dialog {
  padding: $spaceMedium;
  height: 100%;

  &__container {
    .MuiDialog-paper {
      padding: 30px;
      width: 100%;
    }

    .MuiDialog-root {
      visibility: initial;
      z-index: 1800 !important;
    }

    img {
      max-height: 350px;
      height: fit-content;
      width: auto;
      max-width: -webkit-fill-available;
    }
  }

  &__close_icon {
    width: 22px;
    margin-bottom: 22px;
    cursor: pointer;
  }

  &__warning_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -1px;
    margin-bottom: 41px;
  }

  &__image {
    width: 100%;
    min-width: 200px;
  }
}

.MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.MuiAccordion-rounded:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
