.client-text-area {
  width: 100%;
  border-radius: 8px;
  border: solid 2px $adeccoIce;
  padding: 13px 18px 30px 16px;
  color: $adeccoTwilight !important;
  &:focus {
    outline: none;
    border-color: $adeccoRoyal;
    border-width: 2px;
  }
  &--error {
    border-color: red;
  }
}
