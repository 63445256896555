@import '../../../../styles/_variables.scss';

.featureCampaignDetails {
  display: flex;
  align-items: stretch;
}


.featureCampaignDetailsSummary {
  padding-left: 32px;
  width: 100%;
}

.featureCampaignDetailsQr {
  background-color: $backgrounds02;
  width: 730px;
  text-align: center;
  padding-top: 64px;
}

.featureCampaignDetailsQrLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $text01;
  display: block;
  margin-bottom: 32px;
}

.featureCampaignDetailsQrImageContainer {
  background: $backgrounds01;
  display: inline-block;
  padding: 23px 32px;
  border-radius: 8px;
  border: 1px solid $bordersDividers02;
}

.featureCampaignDetailsQrImage {
  width: 200px;
  height: 200px;
}

.featureCampaignDetailsQrCopyButtonContainer {
  display: block;
  margin: 20px;
}

.featureCampaignDetailsQrCopyButton {
}

.featureCampaignDetailsQrDownloadButton {
  background: none;
  margin: auto;
}

.featureCampaignDetailsQrDownloadButtonContainer {
  display: block;
  text-align: center;
}

.featureCampaignDetailsSummaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
}

.featureCampaignDetailsSummaryHeaderEditButton {
  font-size: 14px;
  line-height: 16px;
  color: $textInteractiveLinkOnLightEnabled;
}


.featureCampaignDetailsSummaryContent {
  max-width: 500px;
}

.featureCampaignDetailsSummaryContentStatusTag {
  width: fit-content;
  margin-bottom: 30px;
}

.featureCampaignDetailsSummaryContentItemContainer {
  display: flex;
  font-style: normal;
  font-weight: 400;
}

.featureCampaignDetailsSummaryContentItem {
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;

  border-bottom: 1px solid $bordersDividers01;
  height: 96px;
  align-items: center;
}

.featureCampaignDetailsSummaryContentItemLabel {
  display: block;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.004em;
  color: $text02;
}

.featureCampaignDetailsSummaryContentItemValue {
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: $text01;
}

.featureCampaignDetailsSummaryContentItemIcon {
  font-size: 16px;
  margin: 4px;
}

.featureCampaignDetailsSummaryContentDeactivateButtonContainer {
  margin: 15px auto;
}

.featureCampaignDetailsSummaryContentDeactivateButton {
  height: 40px;
  width: 202px;
  font-size: 14px;
  line-height: 24px;
}
