@import "variables";

.candidates-onboarding-view {
  &__header {
    margin-bottom: $spaceLarge1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-chevron {
    display: flex;
    justify-content: flex-end;
  }

  &__status {
    font-size: 14px;
  }
  &__status-completed {
    font-size: 14px;
    color: $adeccoGreen2;
  }
  &__status-in-progress {
    font-size: 14px;
    color: $adeccoBlue;
  }
  &__date {
    font-size: 12px;
    color: $adeccoGray;
  }

  &__accordion-summary {
    min-height: 60px;
  }

}
