@use 'src/styles/variables' as *;

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}

.base {
  &__container {
    max-width: 436px;
    margin: 0 auto;
  }
  &__option {
    border: 1px solid #ccccd3;
    padding: 12px 16px;
    margin-bottom: 16px;
    border-radius: 8px;
  }
}

.info {
  &__container {
    max-width: 436px;
    margin: 32px auto;
  }
}
