.container {
  height: 64px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #ebebee;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 112px;
}
