@import '../theme1/variables';
.feature-onboarding {
  @import 'app-vertical-stepper-ver1';
}
.onboarding {
  &__submit-button {
    position: absolute;
    bottom: $spaceLarge1;
    max-width: calc(100% - 36px);
    left: 50%;
    transform: translateX(-50%);

    &__relative {
      position: relative;
      max-width: 100%;
    }
  }

  &__header-v1 {
    background-image: linear-gradient(to bottom, #d72620, #d32028, #ce1a2e, #c91534, #c31139);
    .MuiTypography-colorSecondary {
      color: $adeccoIce;
    }
    .MuiSvgIcon-colorSecondary {
      color: $adeccoIce;
    }
  }
  &__header-v2 {
    background-color: $dust;
    .MuiTypography-colorSecondary {
      color: $black;
    }
    .MuiSvgIcon-colorSecondary {
      color: $adeccoGray;
    }
  }

  &__header__keyboard-backspace-box {
    svg {
      font-size: 1.5rem;
    }
  }
  &__header__keyboard-backspace-icon {
    transform: rotate(180deg);
  }

  &__header__dialog {
    .MuiDialog-paper {
      margin: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
    }
  }
  &__dialog__wrapper {
    padding: 2.5rem;
    text-align: center;
  }
  &__header__dialog__wrapper__button {
    border-radius: 0.5rem;
    border: 0.0625rem solid $dust;
    background-color: $dust;
    margin-top: 2.5rem;
    height: 3rem;
    box-shadow: 0 0.3125rem 0.3125rem 0 rgba(0, 0, 0, 0.23);
  }
  &__header__help-btn {
    padding: 1.375rem;
    svg {
      font-size: 1.5rem;
    }
  }

  &__template {
    background: #fff;
  }

  &__template__close-icon {
    margin: 1.5rem;
    color: #da291c;
    font-size: 2rem;
  }

  &__template__content-box {
    border-radius: 2rem 2rem 0 0;
    background: #fff;
    padding: 1rem 1rem 7rem;
  }

  &__page-builder__paragraph {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #06051e;
    font-weight: normal;
  }
  &__page-builder__disclaimer {
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0;
    display: block;
  }
  &__page-builder__unordered-list {
    padding-left: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    li {
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #06051e;
      margin-bottom: 1.125rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__page-builder__ordered-list {
    padding-left: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    li {
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #06051e;
      margin-bottom: 0.5rem;
      font-weight: normal;
    }
  }
  &__page-builder__unordered-list-with-details {
    padding-left: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    li {
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #06051e;
      font-weight: bold;
      margin-bottom: 4px;
    }
    div {
      margin-bottom: 4px;
    }
  }
  &__page-builder__group-details {
    margin-bottom: 1.125rem;
  }
  &__employee-handbook__box {
    a {
      font-size: 0.875rem;
    }
    .active-page__animated {
      position: relative;
      @keyframes transitionAnimation {
        from {
          transform: translateX(100vw);
        }
        to {
          transform: translateX(0);
        }
      }
      animation: 0.3s cubic-bezier(0.4, 0, 0.2, 1) transitionAnimation;
    }
  }
  &__employee-handbook__grid {
    margin-bottom: 1.125rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__progress-circular-button__container {
    & > button:first-of-type {
      min-width: auto;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      margin-bottom: 1rem;
      padding: 0;
      svg {
        path {
          fill: white;
        }
      }
    }

    .disabled-color {
      color: #8c8c94;
    }
  }

  &__text-paragraph__list-item {
    margin-bottom: 1rem;
    list-style: none;
  }
  &__text-paragraph__list-item-subsection {
    list-style: none;
  }
  &__text-paragraph__box {
    font-size: 0.875rem;
    font-weight: bold;
  }
  &__text-paragraph__text {
    margin: 0;
  }

  &__sign-electronically__icon-button-right {
    right: 1.25rem;
  }
  &__sign-electronically__icon-button-left {
    right: 1.25rem;
  }
  &__sign-electronically__border-container {
    padding: 0.9375rem $spaceMediumLarge;
    margin-bottom: 1.25rem;
    border: 1px solid $darkGray;
    background-color: '$adeccoCloud';
    border-radius: $borderRadiusSmall;
  }
  &__documents-page__container {
    background: #f3f3f4;
    position: relative;
  }
  &__documents-page__box {
    background-color: white;
    height: 100%;
    overflow: auto;
    width: 100%;
    padding: 1.25rem 1.25rem 10rem;
    border-radius: 2rem 2rem 0 0;
  }
  &__documents-page__card-box {
    border: 0.125rem solid #efefef;
    border-radius: 0.75rem;
    margin: 0.9375rem 0;
  }
  &__documents-page__card-typography {
    font-weight: bold;
    color: black;
    font-size: 1.125rem;
  }
  &__documents-page__grid {
    margin-top: 1rem;
  }
  &__custom-btn {
    border-radius: 0.5rem;
    border: none;
    background-color: $dust;
    padding: 0.875rem 1.2rem 1rem;
    box-shadow: 0 0.3125rem 0.3125rem 0 $adeccoGray;
    width: 9rem;
    opacity: 1;
    margin-top: 2.5rem;
    :first-of-type span {
      font-size: 1rem;
    }
  }
  &__custom-btn:hover {
    background-color: $dust;
    box-shadow: 0 0.3125rem 0.3125rem 0 $adeccoGray;
  }
  &__get-started-intro-page__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 5;
    color: black;
  }
  &__get-started-intro-page__close-icon {
    margin: 1.5rem;
    color: #da291c;
    font-size: 2rem;
  }
  &__get-started-intro-page__btn {
    padding: 15px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - 3rem);
    margin: 1.5rem auto;
  }
  &__get-started-intro-page__grid {
    margin-bottom: 1.5rem;
    align-items: center;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__get-started-signature_page__header {
    background-color: $adeccoRed;
    color: white;
    padding: 1.1rem 1.3rem;
  }
  &__get-started-signature_page__close-icon {
    font-size: 2.15rem;
    color: white;
  }
  &__progress-dialog-component__wrapper {
    .MuiDialog-paper {
      border-radius: 12pt 12pt 0 0;
      margin: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
      padding: 1.5rem;
    }
  }
  &__progress-dialog-component__button {
    width: 9rem;
    margin-bottom: 1rem;
    padding: 0.875rem 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__vertical-stepper__time-left {
    z-index: 1;
    svg {
      circle {
        stroke-linecap: round;
        stroke-width: 3;
      }
    }
  }
  &__vertical-stepper__time-left-background {
    color: rgba(65, 65, 80, 0.3);
    position: absolute;
    z-index: 0;
    top: 1rem;
    left: 11rem;
    & .MuiCircularProgress-circle {
      stroke-dasharray: 0.1, 4.55 !important;
      stroke-linecap: round;
      stroke-width: 3;
    }
  }
  &__vertical-stepper__wrapper {
    & .MuiStepConnector-line {
      border-left-style: unset;
      border-right-style: solid;
      border-right-width: 1px;
      height: 1.5rem;
    }
    & .MuiStepConnector-vertical {
      padding: 0;
      margin: 0 1.81rem 0 0;
      &.Mui-disabled {
        display: none;
      }
      &.MuiStepConnector-active .MuiStepConnector-line {
        border: unset;
        display: none;
      }
    }
  }
  &__vertical-stepper__step {
    position: relative;
    min-height: 5rem;
    margin-bottom: 1rem;
    background-color: #f4f3f2;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    &.active-step {
      border: 2px solid rgb(239, 239, 239);
      background-color: white;
      cursor: pointer;
      .completed-label {
        margin-right: 1.87rem;
      }
    }
    &.MuiStep-completed {
      background-color: white;
      margin-bottom: 0;
      .completed-label {
        margin-left: 0;
        margin-right: 1.87rem;
      }
      .MuiStepIcon-root {
        display: block;
        color: black;
      }
    }
    & .MuiStepLabel-iconContainer {
      position: absolute;
      right: 1rem;
      padding-right: 0;
    }
    .MuiStepLabel-vertical {
      width: 100%;
    }
    .MuiStepIcon-root {
      display: none;
    }
    .MuiStepLabel-label {
      display: flex;
      align-items: center;
    }
    &__i9__mobile-stepper {
      margin-top: 0.5rem;
      .MuiMobileStepper-dotActive {
        border-radius: 0.5rem;
        background-color: black;
        width: 1.5rem;
      }
    }
  }
  &__disappearing-button {
    font-size: 1rem;
    background-color: $dust;
    padding: 0.875rem 1.2rem 1rem;
    box-shadow: 0 0.3125rem 0.3125rem 0 $adeccoGray;
  }
  &__disappearing-button:hover {
    box-shadow: 0 0.3125rem 0.3125rem 0 $adeccoGray;
    background-color: $dust;
  }
  &__ca-consumer-disclosure-page__button {
    background-color: $dust;
    padding: 0.875rem 1.2rem 1rem;
    box-shadow: 0 0.3125rem 0.3125rem 0 $adeccoGray;
    width: auto;
    font-size: 1rem;
    font-weight: bold;
  }
  &__ca-consumer-disclosure-page__button:hover {
    background-color: $dust;
    box-shadow: 0 0.3125rem 0.3125rem 0 $adeccoGray;
  }

  &__title {
    font-family: Houschka Head, Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 29px;
    white-space: pre-wrap;
  }

  &__description {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.5px;
  }

  @media screen and (max-height: 650px), screen and (max-width: 700px) {
    &__image-container {
      margin-top: 20px;
      margin-bottom: 20px;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}

.sign-electronically {
  &__title {
    color: $darkGray;
  }
}

.sign-documents {
  &__document {
    display: flex;
    justify-content: space-between;
    border-radius: $borderRadiusMedium;
    padding: $spaceMediumLarge;
    margin-top: $spaceSmall;

    &--default {
      color: $primaryDark;
      border: 1px solid $primaryDark;
    }

    &--active {
      background-color: $primaryDark;
      color: $white;
    }
  }

  &__dialog {
    background-color: $dust;
    padding-bottom: 120px;
    border-top-left-radius: $borderRadiusLarge;
    border-top-right-radius: $borderRadiusLarge;
  }

  &__signature {
    padding: $spaceMediumLarge $spaceMedium;
    border-radius: $borderRadiusMedium;
    background-color: $white;
  }

  &__checkbox {
    &-container {
      position: relative;
    }

    &-round {
      label {
        background-color: $white;
        border: 1px solid $primaryDark;
        border-radius: 50%;
        cursor: pointer;
        height: 28px;
        left: 0;
        position: absolute;
        top: 0;
        width: 28px;
      }

      label:after {
        border: 2px solid $white;
        border-top: none;
        border-right: none;
        content: '';
        height: 6px;
        left: 7px;
        opacity: 0;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 12px;
      }

      input[type='checkbox'] {
        visibility: hidden;
      }

      input[type='checkbox']:checked + label {
        background-color: $primaryDark;
        border-color: $primaryDark;
      }

      input[type='checkbox']:checked + label:after {
        opacity: 1;
      }
    }
  }
}

.send-doc-button {
  display: flex;
  cursor: pointer;
}

.error-message {
  color: #f44336;
  margin: 0;
  font-size: 10px;
  font-style: normal;
  margin-top: 3px;
  text-align: left;
  font-family: Nunito, sans-serif;
  font-weight: bold;
  line-height: 1.29;
  font-stretch: normal;
  letter-spacing: 0.2px;
}

.static-text {
  padding-bottom: 16px !important;
  font-weight: 500;
  display: grid;
  gap: 8px;
}

.consent-wo-mark {
  font-size: 14px;
}

h6.static-text {
  font-weight: 600;
}
.sub-controls-wrapper {
  background-color: $bgLightGray;
  padding: 16px 24px;

  & .accordion-header {
    background-color: $bgLightGray;
  }
}
.p-0 {
  padding: 0;
}

.accordian-link-button {
  padding-top: 16px;
  font-weight: 500;
}
