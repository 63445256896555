@import 'variables';

.error-card {
  box-shadow: 0 2px 4px 0 rgba(150, 140, 131, 0.4);
  border: solid 1px $gray;
  padding: 27px 24px;
  border-left: 5px solid $adeccoRed;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__text {
    font-size: 18px;
    padding-right: 8px;
  }
}
