.page {
  height: 100%;
  overflow: auto;
}
.wrapper {
  height: 100%;
  overflow: auto;
  position: relative;
  padding: 28px 8px 32px;
}
