@mixin baseFontStyle($size, $lineHeight) {
  font-size: $size;
  color: rgb(92, 101, 115);
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: $lineHeight;
}

@mixin linkStyle {
  color: #009acc;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.28px;
  text-transform: uppercase;
}

.form-driver {
  min-height: 100vh;
  &__container {
    min-height: calc(100vh - 80px);
    gap: 76px;
    display: flex;
    @include for_breakpoint(tablet) {
      display: grid;
      padding: 0 16px 0 16px;
    }
  }

  &__step-overview {
    padding: 32px 40px;
    box-shadow: 4px 2px 16px rgba(28, 48, 75, 0.08);
    min-height: 100%;
    max-height: calc(100vh - 80px);
    overflow: auto;
    width: 25%;
    position: sticky;
    top: 80px;
    @include for_breakpoint(tablet) {
      display: none;
    }
  }
  &__form-content {
    display: flex;
    overflow: auto;
    max-height: none;
    flex: 1;
    min-height: 100%;
    & > div {
      flex: 1;
    }

    & > div:first-child {
      width: 100%;
      max-width: 568px;
      @include for_breakpoint(tablet) {
        order: 1;
        max-width: 100%;
      }
    }

    @include for_breakpoint(tablet) {
      display: grid;
      max-width: 100%;
      width: 100%;
      padding-left: 0;
      grid-template-columns: auto;
    }
  }

  .control-element {
    &__INTRODUCTION_CHOICE {
      @include baseFontStyle(16px, 24px);
      padding-bottom: 0 !important;
    }
    &__DECISION_INTRODUCTION {
      @include baseFontStyle(20px, 32px);

      & > a {
        @include linkStyle;
      }
    }

    &__VEHICLE_OPTION_PRE_INTRODUCTION {
      @include baseFontStyle(16px, 24px);
      padding-bottom: 0 !important;
    }

    &__VEHICLE_OPTION_INTRODUCTION {
      @include baseFontStyle(16px, 24px);
      padding-bottom: 0 !important;

      & > a {
        @include linkStyle;
      }
    }
  }
}
