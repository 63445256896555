@import './variables';
// tag-ds sync
#root .tag-ds, body .tag-ds {
	--full-palette-tertiary-600: #eb9fbb;
	--full-palette-tertiary-500: #e87a71;
	--full-palette-tertiary-400: #ff8e4f;
	--full-palette-tertiary-300: #ffc133;
	--full-palette-tertiary-200: #81a4ff;
	--full-palette-tertiary-100: #7e63e8;
	--full-palette-secondary-700: #004d66;
	--full-palette-secondary-600: #0e6987;
	--full-palette-secondary-500: #107b9e;
	--full-palette-secondary-400: #009acc;
	--full-palette-secondary-300: #33cdff;
	--full-palette-secondary-200: #99e6ff;
	--full-palette-secondary-100: #ccf2ff;
	--full-palette-primary-700: #000306;
	--full-palette-primary-600: #030c18;
	--full-palette-primary-500: #1c304b;
	--full-palette-primary-400: #495667;
	--full-palette-primary-300: #727f8f;
	--full-palette-primary-200: #8894a5;
	--full-palette-primary-100: #ced7e4;
	--full-palette-accent-700: #275956;
	--full-palette-accent-600: #367c78;
	--full-palette-accent-500: #5cb8b2;
	--full-palette-accent-400: #8dcdc9;
	--full-palette-accent-300: #aedcd9;
	--full-palette-accent-200: #ceeae8;
	--full-palette-accent-100: #eff8f7;
	--full-palette-neutrals-800: #1c304b;
	--full-palette-neutrals-700: #5c6573;
	--full-palette-neutrals-600: #7c828a;
	--full-palette-neutrals-500: #adadb5;
	--full-palette-neutrals-400: #ccccd3;
	--full-palette-neutrals-300: #e0e0e4;
	--full-palette-neutrals-200: #ebebee;
	--full-palette-neutrals-100: #f8f8f9;
	--full-palette-neutrals-0: #fff;
	--borders-dividers-buttons-disabled: #ced7e4;
	--borders-dividers-buttons-focused: #{$accentLight};
	--borders-dividers-buttons-pressed: #{$accent};
	--borders-dividers-buttons-hover: #{$accent};
	--borders-dividers-buttons-enabled: #1c304b;
	--borders-dividers-interactive-error: #e87a71;
	--borders-dividers-interactive-focused: #{$accentLight};
	--borders-dividers-interactive-active: #{$accentLight};
	--borders-dividers-interactive-disabled: #ced7e4;
	--borders-dividers-interactive-pressed: #{$accent};
	--borders-dividers-interactive-hover: #{$accentLight};
	--borders-dividers-interactive-enabled: #{$accentDarker};
	--borders-dividers-cta-focused: #7c828a;
	--borders-dividers-info: #{$accentLight};
	--borders-dividers-error: #e87a71;
	--borders-dividers-success: #73d85a;
	--borders-dividers-alert: #ffc133;
	--borders-dividers-on-secondary: #107b9e;
	--borders-dividers-on-accent: #367c78;
	--borders-dividers-on-primary: #495667;
	--borders-dividers-02: #ccccd3;
	--borders-dividers-01: #ebebee;
	--chart-07: #eb9fbb;
	--chart-06: #e87a71;
	--chart-05: #ff8e4f;
	--chart-04: #ffc133;
	--chart-03: #81a4ff;
	--chart-02: #7e63e8;
	--chart-01: #5cb8b2;
	--chart-base: #ebebee;
	--icons-buttons-disabled: #ced7e4;
	--icons-buttons-default-inverse: #fff;
	--icons-buttons-default: #1c304b;
	--icons-feedback-info: #33cdff;
	--icons-feedback-error: #e87a71;
	--icons-feedback-success: #73d85a;
	--icons-feedback-alert: #ffc133;
	--icons-interactive-on-dark-disabled: #adadb5;
	--icons-interactive-on-dark-pressed: #{$accent};
	--icons-interactive-on-dark-hover: #99e6ff;
	--icons-interactive-on-dark-enabled: #33cdff;
	--icons-interactive-on-light-disabled: #adadb5;
	--icons-interactive-on-light-pressed: #004d66;
	--icons-interactive-on-light-hover: #0e6987;
	--icons-interactive-on-light-enabled: #107b9e;
	--icons-inverse: #fff;
	--icons-accent: #5cb8b2;
	--icons-03: #adadb5;
	--icons-02: #5c6573;
	--icons-01: #1c304b;
	--surface-3x: 8px 8px 28px 0px #1c304b14;
	--surface-2x: 6px 4px 24px 0px #1c304b14;
	--surface-1x: 4px 2px 16px 0px #1c304b14;
	--surface-feedback-info: #{$accentLighter};
	--surface-feedback-error: #f7d3d0;
	--surface-feedback-success: #dcf5d6;
	--surface-feedback-alert: #fff0cc;
	--surface-interactive-pressed: #ccf2ff;
	--surface-interactive-disabled: #f8f8f9;
	--surface-interactive-default: #fff;
	--surface-gradient: linear-gradient(180deg,#8dcdc9 0.68%,#5cb8b2);
	--surface-accent: #{$accent};
	--surface-03: #1c304b;
	--surface-02: #f8f8f9;
	--surface-01: #fff;
	--backgrounds-buttons-disabled: #ced7e4;
	--backgrounds-buttons-focused: #1c304b;
	--backgrounds-buttons-pressed-02: #{$accentLighter};
	--backgrounds-buttons-pressed-01: #495667;
	--backgrounds-buttons-hover: #030c18;
	--backgrounds-buttons-enabled: #1c304b;
	--backgrounds-feedback-info-icon-bg: #33cdff;
	--backgrounds-feedback-error-icon-bg: #e87a71;
	--backgrounds-feedback-success-icon-bg: #73d85a;
	--backgrounds-feedback-alert-icon-bg: #ffc133;
	--backgrounds-interactive-selected-02: #{$accentLighter};
	--backgrounds-interactive-selected-01: #ebebee;
	--backgrounds-interactive-active: #{$accent};
	--backgrounds-interactive-disabled: #ced7e4;
	--backgrounds-interactive-pressed: #{$accentLighter};
	--backgrounds-interactive-default: #fff;
	--backgrounds-action-cta-enabled-dark: #fff;
	--backgrounds-action-cta-disabled: #ebebee;
	--backgrounds-action-cta-focused: #7c828a;
	--backgrounds-action-cta-pressed: #adadb5;
	--backgrounds-action-cta-hover: #5c6573;
	--backgrounds-action-cta-enabled: #7c828a;
	--backgrounds-skeleton-primary: #495667;
	--backgrounds-skeleton-accent: #8dcdc9;
	--backgrounds-overlay: #1c304b7a;
	--backgrounds-skeleton: #ebebee;
	--backgrounds-gradient: linear-gradient(180deg,#8dcdc9 0.68%,#5cb8b2);
	--backgrounds-accent: #{$accentLight};
	--backgrounds-secondary: #{$accent};
	--backgrounds-05: #727f8f;
	--backgrounds-04: #1c304b;
	--backgrounds-03: #ebebee;
	--backgrounds-02: #f8f8f9;
	--backgrounds-01: #fff;
	--text-buttons-default-inverse: #fff;
	--text-buttons-disabled: #ced7e4;
	--text-buttons-default: #1c304b;
	--text-interactive-link-on-dark-disabled: #adadb5;
	--text-interactive-link-on-dark-pressed: #{$accent};
	--text-interactive-link-on-dark-hover: #99e6ff;
	--text-interactive-link-on-dark-enabled: #33cdff;
	--text-interactive-link-on-light-disabled: #adadb5;
	--text-interactive-link-on-light-pressed: #004d66;
	--text-interactive-link-on-light-hover: #{$accentDarker};
	--text-interactive-link-on-light-enabled: #{$accentDark};
	--text-cta-default-inverse: #fff;
	--text-cta-disabled: #5c6573;
	--text-cta-default: #1c304b;
	--text-accent-on-dark: #5cb8b2;
	--text-inverse: #fff;
	--text-accent-on-light: #367c78;
	--text-on-accent: #1c304b;
	--text-03: #adadb5;
	--text-02: #5c6573;
	--text-01: #1c304b;
}
