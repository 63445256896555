.renderDocumentContainer {
  position: fixed;
  left: 80px;
  right: 0;
  bottom: 0;
  top: 0;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.form {
  height: 100%;
  padding: 32px;
  overflow-x: auto;
  min-width: 328px;
  max-width: 396px;
}

.selection {
  padding: 32px;
  overflow: scroll;
  height: 100vh;
  min-width: 218px;
  max-width: 266px;
  background-color: #f8f8f9;

  &__item {
    max-width: 100%;
    height: auto;
    margin-bottom: 18px;
    border: 4px solid transparent;
    border-width: 4px;
    border-style: solid;
    border-radius: 8px;
  }
}

.preview {
  background-color: #ebebee;
  position: relative;
    overflow: scroll;
    height: 100%;
    width: 100%;
  }
  
  .previewContainer {
  flex-grow: 1;
  position: relative;
  height: 100%;
  width: 100%;
  &__controls {
    opacity: 0.2;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 40px;
    width: 400px;
    height: 100px;
    color: #1c304b;
    &:hover {
      opacity: 1;
    }
    &Info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebebee;
      padding: 12px 16px 8px;
    }
    &Actions {
      display: flex;
      padding: 12px 16px 8px;
      justify-content: space-between;
    }
    &ActionsButton {
      cursor: pointer;
      user-select: none;
      color: #1c304b;
    }
    &ActionsButtonDisabled {
      cursor: not-allowed;
      user-select: none;
      color: #ced7e4;
    }
  }
}
.uploadControls {
  width: 304px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
