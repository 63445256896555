@import 'variables';

.MuiButton-label {
  text-transform: none !important;
}

.feature-dashboard {
  margin-top: 0;
  overflow: initial !important;
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__progress {
    &-circle-background-color {
      position: absolute;
      top: 0;
      left: 0;
      svg {
        circle {
          stroke: white;
        }
      }
    }
    &-outer-circle {
      position: absolute;
      top: 0;
      left: 0;
    }
    &-short-name,
    &-full-name {
      font-size: 14px;
    }
    &-full-name {
      margin-bottom: 8px;
    }
    &-percentage {
      color: $adeccoPink;
      &:after {
        content: '%';
        font-size: 18px;
        color: $white;
        position: relative;
        top: -16px;
        left: 4px;
      }
    }
    circle {
      stroke-linecap: round;
      stroke-width: 2;
      stroke: #7c71a2;
    }
    &-filled {
      color: $white;
    }

    &--first {
      circle {
        stroke: $adeccoRed;
      }
    }
    &--second {
      circle {
        stroke: $adeccoMarigold;
      }
    }
    &--third {
      circle {
        stroke: $adeccoTeal;
      }
    }
  }

  // New
  &__table-sorting {
    font-size: 12px;
    user-select: none;
    cursor: pointer;
    opacity: 0;
    &--active {
      display: block;
      font-size: 12px;
      user-select: none;
      cursor: pointer;
    }
    transition: all 100ms ease;
    &:hover {
      opacity: 1;
    }
  }
  &__header {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__table-head {
    border-top: 1px solid #ccccd7;
    border-bottom: 1px solid #ccccd7;
  }
  &__calendar-popover {
    .MuiPopover-paper {
      width: 384px;
    }
  }
  &__table-small-text {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #968c83;
  }
  &__table-red-text {
    font-family: 'HouschkaHead', sans-serif;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
    color: $primaryBrandRed;
  }
  &__calendar-tabs {
    padding: 12px 8px 19px;
    .MuiTab-root {
      border: 2px solid #efefef;
      padding: 7px 12px;
      color: black;
      margin-right: 0;
      font-family: 'HouschkaHead', sans-serif;
      flex: 1;
      min-height: auto;
      .MuiTab-wrapper {
        font-size: 14px;
      }
      &:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    .Mui-selected {
      background-color: $adeccoRed;
      color: white;
    }
    .MuiTabs-indicator {
      background-color: transparent;
    }
    &-tabs {
      border: 1px solid black;
    }
  }
  &__calendar-preset-item {
    &--active {
      .feature-dashboard__calendar-preset-item-name {
        color: $adeccoRed;
      }
    }
    &-name {
      font-family: 'HouschkaHead', sans-serif;
      font-size: 14px;
      cursor: pointer;
    }
    &-value {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      color: #968c83;
    }
  }

  &__quick-filter-grid {
    // margin-bottom: $spaceHuge2;

    // &-item-selected:not(:last-child),
    // &-item-selected {
    //   width: 18%;
    //   cursor: pointer;
    //   position: relative;
    //   border: 1px solid $adeccoGray40;
    //   border-radius: $borderRadiusSmall;
    //   padding: $spaceMedium;
    //   margin: $spaceSmall;

    //   background-color: $primaryBrandRed;
    //   & > div {
    //     color: $white;
    //   }

    //   & > p {
    //     color: $white;
    //   }

    //   & > svg {
    //     position: absolute;
    //     right: $spaceMedium;
    //     color: $white;
    //   }
    // }

    // &-item {
    //   width: 18%;
    //   cursor: pointer;
    //   border: 1px solid $adeccoGray40;
    //   border-radius: $borderRadiusSmall;
    //   padding: $spaceMedium;
    //   margin: $spaceSmall;

    //   &__number {
    //     color: $primaryBrandRed;
    //     font-family: 'Roboto', sans-serif;
    //     line-height: 48px;
    //     letter-spacing: 1.33px;
    //     font-weight: 800;
    //     font-size: 32px;
    //     text-align: center;
    //   }
    //   &__title {
    //     text-align: center;
    //     font-family: 'HouschkaHead', sans-serif;
    //     font-size: 14px;
    //     line-height: 18px;
    //     letter-spacing: 0.7px;
    //   }
    //   &__description {
    //     text-align: center;
    //     font-family: 'Roboto', sans-serif;
    //     font-size: 12px;
    //     line-height: 18px;
    //     letter-spacing: 0.1px;
    //     color: #968c83;
    //   }
    // }
  }

  // Datepicker overrides
  &__datepicker {
    height: 443px;
    .DateRangePicker {
      height: 370px;
      width: 100%;
    }
    .DayPickerNavigation__horizontal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      top: 35px;
    }
    .DateInput_input {
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      line-height: 22px;
      padding: 0 12px;
      &::placeholder {
        color: black;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
      }
    }
    .DateInput {
      width: 148px;
      border: 2px solid #efefef;
      border-radius: 4px;
    }
    .DateInput_input__focused {
      border: 2px solid #d9281c;
      border-radius: 4px;
    }
    .DateRangePickerInput_arrow {
      display: none;
    }
    .DayPicker__withBorder {
      box-shadow: none;
    }
    .DateRangePickerInput {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .DateInput_fang {
      display: none;
    }
    .DateRangePicker_picker {
      top: 30px !important;
    }

    .CalendarDay__default,
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      border: 0;
    }
    .CalendarDay__default {
      &:hover {
        background-color: #fbe9e8;
      }
    }
    &-label {
      font-size: 14px;
      font-family: 'HouschkaHead', sans-serif;
      letter-spacing: 0.7px;
      margin-bottom: 12px;
      &:first-of-type {
        margin-right: 100px;
      }
    }
    .CalendarMonth_caption {
      font-family: 'HouschkaHead', sans-serif;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: black;
    }
    .CalendarDay__selected {
      border-radius: 50% 0 0 50%;
      z-index: 4;
      position: relative;
      background-color: #fbe9e8;
      &:after {
        background-color: #d9281c;
        border-radius: 50%;
        content: '';
        height: 100%;
        width: 100%;
        display: inline-block;
        position: absolute;
        top: 0px;
        z-index: -1;
        left: 0;
      }
      &_start {
        border-radius: 50% 0 0 50%;
      }
      &_end {
        border-radius: 0 50% 50% 0;
      }
    }
    .CalendarDay__selected_span {
      background-color: #fbe9e8;
      color: black;
    }
    .CalendarDay__hovered_span {
      &:hover {
        background-color: #fbe9e8;
      }
    }
    .CalendarDay__hovered_span {
      background-color: #fbe9e8;
      color: black;
    }
  }
  &__side-navigation {
    z-index: 2;
    top: 80px !important;
  }
}

.border-color-primary {
  border-color: $adeccoRed !important;
}

.email-label {
  color: #968c83;
}

.email-value {
  color: #273446;
  margin-bottom: 15px;
  margin-top: 5px;
}

.integration-state-prop {
  margin: 0;
  white-space: pre-wrap;

  &--active {
    margin: 0;
    cursor: pointer;
    color: $adeccoRed;
  }
}
