@import "variables";

.candidate-profile-delete-modal {
  & .MuiDialogTitle-root {
    & .MuiTypography-root {
      display: flex;
      align-items: center;

      & > svg {
        color: $adeccoOrange;
      }
      & > .MuiTypography-root {
        color: $black;
      }
    }
  }

  & .MuiDialogActions-root {
    display: flex;
    justify-content: flex-end;
  }
  & .MuiDialogContent-root {
    & > .MuiTypography-root {
      color: $black;
    }
  }
}
