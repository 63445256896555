.table {
  &__container {
    width: auto;
    max-width: 1248px;
    margin: 32px auto 0;
    padding-left: 32px;
    padding-right: 32px;
    table {
      border-collapse: collapse !important;
    }
    tr {
      border-bottom: 1px solid #ebebee !important;
    }
  }
}
