@use 'src/styles/variables' as *;

.newControlSelector {
  &__tileContainer {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  &__tile {
    width: 148px;
    min-height: 152px;
    border: 1px solid $bordersDividers01;
    border-radius: 8px;
    background-color: white;
    padding: 24px 16px;
    cursor: pointer;
    &:hover {
      border-color: #009acc;
      div {
        color: #0e6987;
      }
    }
  }
  &__tileIcon {
    background-color: $backgrounds02;
    padding: 16px;
    border-radius: 50%;
    margin-bottom: 16px;
  }
}
