.sidebar {
    box-shadow: 0 4px 16px rgba(28, 48, 75, 0.1);
    height: 100%;
    padding: 32px;
    position: relative;
}

.sidebar :global(.tree-container > .tree-container) {
    padding-left: 16px;
    margin-bottom: 16px;
}

.sidebar :global(.tree-container:first-child) {
    position: relative;
}

.treeContainerWrapper {
    display: grid;
}

.treeContainerWrapper ul {
    display: grid;
}

.tree-view-element {
    white-space: normal;
}

.success > span {
    color: #73d85a;
}

.inProgress:not([data-disabled="true"]):not(.success) {
    font-weight: bold !important;
}

.notStarted:hover {
    color: var(--text-01) !important;
}
