@import 'variables';

.feature-candidates-profile {
  &__space-between > .MuiAccordionSummary-content {
    justify-content: space-between;
  }
  &__notes-accordion {
    .MuiAccordionDetails-root {
      flex-direction: column;
      .MuiFormControl-root {
        margin-bottom: $spaceMedium;
      }
    }
    display: none;
  }
  .admin-select-button {
    margin: $spaceSmall;
  }
  &-side-page {
    width: 100%;
    &__header {
      padding: 28px 40px;
      background-color: #f4f3f2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-title {
        font-family: 'HouschkaHead', sans-serif;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
      }
    }

    &-hiring-data-item {
      margin: $spaceSmall;
    }
    &-hiring-data-item-end {
      display: flex;
      justify-content: flex-end;
    }
    &-hiring-data-row-accordion {
      display: block;
    }
    &-hiring-data-item-flex {
      display: flex;
      margin-bottom: $spaceMedium;
    }
  }

  &__section-button {
    padding: $spaceMedium $spaceLarge1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $adeccoSecondaryGrayLight2;
    border-bottom: 1px solid $adeccoSecondaryGrayLight2;

    &--grey {
      background-color: $adeccoSecondaryGrayLight2;
    }
  }

  &__document-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;
  }

  &__user-info {
    padding: $spaceLarge1 $spaceLarge1;
    border-bottom: 2px solid $adeccoRed;
  }
  
  &__oo-user-info {
    padding: $spaceLarge1 $spaceSmall;
    border-bottom: 2px solid $adeccoRed;
  }

  &__avatar-img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }

  &__oo-avatar-img {
    width: 100px;
    height: 100px;
  }

  &__register-date {
    font-family: 'Roboto', sans-serif;
  }

  &__contact {
    list-style-type: none;

    a {
      color: $adeccoRed;
      text-decoration: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
    }
  }

  &__main {
    margin: $spaceLarge1 $spaceLarge1;
  }

  &__actions {
    padding: 0 $spaceLarge1 0 $spaceSmall;

    &-header {
      margin-bottom: $spaceLarge1;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      padding: 0 0 $spaceLarge1 0;
    }

    &-button {
      flex: 1;
      margin-right: $spaceMedium;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &-button-dark {
      background-color: $primaryDark;
      margin-right: $spaceMedium;
      flex: 1;
      color: $white;
      &:hover {
        background-color: $primaryDark;
        opacity: 0.8;
      }
    }
  }

  &__actions-notes {
    padding: $spaceMedium;
    display: flex;
    flex-direction: column;
    padding-bottom: $spaceLarge1;
    background-color: $adeccoSecondaryGrayLight2;
    & > textarea {
      margin: $spaceMedium 0;
    }
  }

  &__uploaded-documents {
    border-bottom: 1px solid $adeccoGray;
    margin: $spaceSmall 0;
    padding-bottom: $spaceLarge1;
  }

  &__uploaded-document-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;

    & > svg {
      margin-left: $spaceSmall;
    }
  }
  &__integration-state-faq {
    color: $adeccoRed;
  }
}

.admin-approve-candidate {
  margin-bottom: 40px;
  width: 32%;
  &__reason {
    width: 30%;
  }
}

.header__message-number {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  background-color: $adeccoRed;
  border-radius: $borderRadiusMedium;
  color: $white;

  .MuiTypography-caption {
    color: $white !important;
  }
}
