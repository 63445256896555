@use 'src/styles/variables' as *;

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    border: 1px solid $text01;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    line-height: 0;
    cursor: pointer;
    background-color: white;
    z-index: 2;
    &:before {
      content: '';
      background-color: white;
      width: 16px;
      height: 10px;
      display: inline-block;
      position: rea;
      position: absolute;
      right: 34px;
      top: 14px;
      z-index: 2;
    }
  }
  span {
    font-size: 16px;
  }
}

.item {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    &:after {
      content: '';
      background-color: $bordersDividers01;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
    }

    &-title {
      background-color: white;
      z-index: 2;
      padding-right: 16px;
      color: #5c6573;
    }
  }
  &__icon {
    border: 1px solid $text01;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    line-height: 0;
    cursor: pointer;
    background-color: white;
    z-index: 2;
    &:before {
      content: '';
      background-color: white;
      width: 16px;
      height: 10px;
      display: inline-block;
      position: rea;
      position: absolute;
      right: 34px;
      top: 14px;
      z-index: 2;
    }
  }
  &__input {
    width: 328px;
    margin-bottom: 16px;
  }
}
