.feature-candidate-profile {
  &__documents {
    &-item-group {
      margin-bottom: 36px;
    }
    &-item {
      display: flex;
      padding: 16px 24px;
      border-radius: 8px;
      background-color: #f8f8f9;
      margin-bottom: 12px;
      align-items: center;
      position: relative;
    }
    &-item-actions {
      width: 96px;
      display: flex;
      justify-content: flex-end;
      margin-left: 31px;
    }
    &-item-status {
      margin-left: auto;

      h6 {
        color: #5c6573;
        letter-spacing: 0.004em;
      }
    }
    &-details-modal {
      .MuiDrawer-paper {
        width: 50%;
        padding: 88px 32px 0;
        @include for_breakpoint(full-hd) {
          padding-left: 48px;
        }
      }
      &-left-side {
        padding: 32px 32px 32px 0;
        overflow: scroll;
        height: 100%;

        @include for_breakpoint(full-hd) {
          padding-right: 48px;
        }

        & > img {
          max-width: 100%;
          height: auto;
          cursor: zoom-in;
        }
      }
      &-right-side {
        background-color: #f8f8f9;
        height: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 32px;
        overflow-x: auto;
      }
    }
  }
}
