@import "variables";
.app-dialog {
    .MuiPaper-root.MuiDialog-paper {
        padding: $spaceMedium
    }
    .MuiDialogContent-root {
        padding: 0;
    }
    .MuiDialogTitle-root {
        padding-top: 0;
    }
    .MuiDialog-paperFullScreen {
        max-width: 900px;
        border-radius: 8px 8px 0 0;
        max-height: 95vh;
    }
    .shift-schedule__paper {
        .MuiGrid-item svg{
            position: absolute;
            right: 26px;
            top: 14px;
        }
    }
}
