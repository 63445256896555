@import './theme2/variables';
@import './theme2/typography';
@import './theme2/registration-first-step';
@import './theme2/registration-second-step';
@import './_configurationStyles';
.client {
  @import './theme2/main-template';
  @import './theme2/wrappers';
  @import './theme2/buttons';
  @import './theme2/tag';
  @import './theme2/navigation';
  @import './theme2/page-header';
  @import './theme2/modal-template';
  @import './theme2/create-shift';
  @import './theme2/chip-component';
  @import './theme2/multiple-select';
  @import './theme2/shift-instance-card';
  @import './theme2/assignee-card';
  @import './theme2/error-card';
  @import './theme2/client-stepper';
  @import './theme2/sub-header-shaded-box';
  @import './theme2/client-chip';
  @import './theme2/date-and-time-input';
  @import './theme2/client-input-expandable';
  @import './theme2/day-picker';
  @import './theme2/client-text-area';
  @import './theme2/client-map-with-info';
  @import './theme2/client-chip-day';
  @import './theme2/client-chip-night';
  @import './theme2/header';
  @import './theme2/client-stepper';
  @import './theme2/double-arrow';
  @import './theme2/angled-edge';

  font-family: 'Roboto', sans-serif;
  // margin-left: 72px;
  .container {
    position: relative;
  }
  .container-shaded-box {
    position: relative;
    height: 184px;
    background-color: $dust;
    padding-top: 18px;
  }

  /* Scrollbar style */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $dust;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba($dust, 0.8);
  }
}

@font-face {
  font-family: 'HouschkaMedium';
  font-style: normal;
  fontweight: 400;
  src: local('HouschkaHead'), local('HouschkaHead-Medium'), url('/fonts/HouschkaHead-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'HouschkaHead';
  font-style: normal;
  fontweight: 600;
  src: local('HouschkaHead'), local('HouschkaHead-DemiBold'), url('/fonts/HouschkaHead-DemiBold.otf') format('opentype');
}

// dropdown-filters clientMultipleSelect style
.popover-c {
  .MuiList-padding {
    padding: 0;
    width: 304px;
  }

  .MuiButton-outlinedSizeSmall {
    padding: 7px 10px;
    min-width: 72px;
  }

  .MuiButton-containedSizeSmall {
    padding: 8px 10px;
    min-width: 72px;
  }

  .MuiDivider-root {
    height: 1px;
    background-color: rgba(150, 140, 131, 0.6);
  }

  .MuiListItemText-root {
    .MuiTypography-body1 {
      @include nunito-regular(16px, 1.5);
    }
  }

  .MuiListItem-gutters {
    box-shadow: inset 0 -1px 0 0 rgba(150, 140, 131, 0.2);
  }

  .selected {
    background-color: $dust;
    -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    .MuiTypography-body1 {
      @include nunito-bold(16px, 1.5);
      -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .selected {
    .MuiSvgIcon-root {
      animation-name: showItem;
      animation-duration: 0.3s;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  @keyframes showItem {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

// Calendar element for floating panels - not inside main element
.b-float-root {
  // Calendar event popups (on click)
  .b-eventtip {
    border-radius: 12px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12),
      0 12px 17px 2px rgba(0, 0, 0, 0.14);
    .b-panel-header.b-eventtip-header {
      background: $white;
      border-top-left-radius: $borderRadiusMedium;
      border-top-right-radius: $borderRadiusMedium;
      @include houschkaHead-medium(21px, 1.43);
      color: $black;
      padding: 1.5rem 1.3rem 0;
      .b-icon {
        color: $adeccoRed;
      }
    }
    .b-eventtip-content {
      background: $white;
      color: $black;
      border-bottom-left-radius: $borderRadiusMedium;
      border-bottom-right-radius: $borderRadiusMedium;
      padding: 0 1.5rem 1.5rem;
    }

    .zero-calendar__event-tooltip {
      &__name {
        @include houschkaHead-bold(16px, 1.5);
        color: $adeccoRed;
      }
      &__list {
        @include nunito-regular(16px, 2.375rem);
        list-style: none;
        padding: 0;
      }
      &__list__icon {
        color: $adeccocLightGray;
        width: 20px;
        display: inline-block;
        margin-right: 20px;
      }
      &__list__small-text-item {
        font-size: 14px;
        line-height: 32px;
        span {
          color: $adeccoGray;
        }
      }
    }

    .role-title {
      height: 2rem;
      font-weight: normal;
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      background-color: $pastelTeal;
      color: $greenLabel;
    }

    .icon {
      padding-left: 2.25rem;
    }

    .icon-role {
      background: url('/images/calendar-icons/job.png') left center no-repeat;
    }

    .icon-department {
      background: url('/images/calendar-icons/department.png') left center no-repeat;
    }

    .icon-calendar {
      background: url('/images/calendar-icons/job.png') left center no-repeat;
    }

    .icon-clock {
      background: url('/images/calendar-icons/time.png') left center no-repeat;
    }

    .icon-location {
      background: url('/images/calendar-icons/location.png') left center no-repeat;
    }

    .icon-price {
      background: url('/images/calendar-icons/salary.png') left center no-repeat;
    }

    .b-icon-add:before {
      content: url('/images/calendar-icons/edit.png');
    }
    .b-popup-close:before {
      content: url('/images/calendar-icons/close.png');
    }
  }

  .b-cal-event-list {
    .zero-event {
      // remove user assigned list
      &__assigned-user-list {
        display: none;
      }
      // styled job name
      &__name {
        @include nunito-semi-bold(16px, 1.38);
        margin: 0;
      }
      // remove job's working time
      &__date-time {
        display: none;
      }
      // remove time header from event
      .b-event-header {
        display: none;
      }
    }
  }
}
//calendar master search input style
.zero-calendar__master-search {
  .MuiOutlinedInput-root {
    max-height: 46px;
  }
  .MuiOutlinedInput-input {
    padding: 13px 13px 13px 0;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $adeccoRed;
  }
}

.navlink-item {
  &:active {
    text-decoration: none;
  }
}
.navlink-item--active {
  .navlink-item-button {
    background-color: $adeccoRoyal;
    &:hover,
    :focus {
      background-color: $adeccoRoyal;
    }
  }
}

.MuiAutocomplete-root > input {
  border: none !important;
}
