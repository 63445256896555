@import "variables";

.navigation {
  background-color: $dust;
  display: flex;
  box-shadow: none;
  padding: 25px 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &__container {
    display: flex;
    list-style-type: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding-left: 0;
    align-items: center;
  }
  &__item {
    margin-right: 56px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    text-decoration: none;
    color: $black;
    font-weight: 600;
    transition: all 300ms ease;
    white-space: nowrap;
    &:hover {
      color: $adeccoRed;
    }
    &--active {
      color: $adeccoRed;
      &:before {
        content: '';
        width: 100%;
        display: block;
        height: 4px;
        background-color: $adeccoRed;
        top: -28px;
        position: relative;
        margin-top: -4px;
      }
    }
  }
}
