@import "variables";

.modal-template {
  &__container {
    padding: 40px 40px 134px;
  }
  &__header {
    background: $redGradient;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 40px;
  }
  &__heading {
    color: $white;
  }
  &__close {
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;
  }
}
