.side-page {
  width: 928px;
  &__header {
    padding: 28px 40px;
    background-color: #f4f3f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      font-family: 'HouschkaHead', sans-serif;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.2px;
    }
  }
}
