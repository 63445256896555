@use 'src/styles/variables' as *;

.container {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: white;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    border: 1px solid $text01;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    line-height: 0;
    cursor: pointer;
    background-color: white;
    z-index: 2;
    &:before {
      content: '';
      background-color: white;
      width: 16px;
      height: 10px;
      display: inline-block;

      position: absolute;
      right: 34px;
      top: 14px;
      z-index: 2;
    }
    &:hover {
      border-color: #009acc;
    }
  }
  span {
    font-size: 16px;
  }
}

.addButton {
  display: flex;
  justify-content: flex-end;
}

.item {
  margin-top: 8px;
  border-radius: 8px;
  position: relative;
  &__input {
    div {
      width: 320px;
    }
  }
  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
}

.condition {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    &:after {
      content: '';
      background-color: $bordersDividers01;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
    }

    &-title {
      background-color: white;
      z-index: 2;
      padding-right: 16px;
    }
  }
  &__boolean {
    &-container {
      display: flex;
    }
    &-item {
      flex: 1;
      padding: 8px 24px;
      display: flex;
      justify-content: center;
      border: 1px solid black;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      &--left {
        border-radius: 8px 0 0 8px;
        position: relative;
        left: 1px;
        &:hover {
          z-index: 1;
        }
      }
      &--right {
        border-radius: 0 8px 8px 0;
        &:hover {
          z-index: 1;
        }
      }
      &:hover {
        background-color: $accentLighter;
        border: 1px solid $accentLight;
      }
      &--active {
        background-color: $accentLighter;
        border: 1px solid $accentLight;
      }
    }
  }
}
