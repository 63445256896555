@import 'variables';

.manual-action-dialog {
  &-title {
    & > h2 {
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: 1.22;
      font-stretch: normal;
      letter-spacing: 1px;
      flex: 1;
      justify-content: space-between;
      display: flex;
    }
  }
  & .MuiDialog-paper {
    background-color: $adeccoSecondaryGrayLight2;
    min-width: 800px;
  }

  &__icon {
    color: $primaryBrandRed
  }

  & .MuiDialogContent-root {
    & strong {
      color: $primaryBrandRed
    }
  }

  &-grid {
    margin-bottom: $spaceMedium;
    &-item {
      display: flex;
      margin-bottom: $spaceMedium;
      background-color: $white;
      padding: $spaceMedium;

      &__name {
        width: 25%;
        &-value {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.7px;
          color: $primaryBrandRed !important;
        }
      }

      &__id {
        width: 25%;
        flex: 1;
        &-value {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.7px;
          color: $adeccoMidnight !important;
        }
      }

      &__action {
        width: 100px;
        display: flex;
        justify-content: center;
        text-align: center;
        & > svg {
          color: $adeccoGreen;
        }
      }
    }
  }


}
