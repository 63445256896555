@import '../variables';
.admin-language-header {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $mediumGray;
  align-items: center;
  padding: 0 104px 0 104px;
  height: 80px;
  .MuiSelect-icon {
    margin-right: 0;
  }

  &__select-language-box {
    display: flex;
    height: 100%;
    align-items: center;
    .MuiInputBase-input {
      border: 0 !important;
      padding-right: 25px !important;
      font-size: 12px;
      color: #5c6573;
    }

    div {
      background-color: transparent;
      select,
      select:hover,
      .MuiOutlinedInput-input {
        background-color: white !important;
      }
      svg {
        fill: #5c6573;
        right: 0;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

.oo-filters-container {
  padding: 24px;
  width: 328px;
  background-color: $lightGray;
  .input-wrapper > .dropdown {
    width: 100%;
  }
  .input-wrapper_disabled > .dropdown {
    width: 100%;
  }
  .oo-filter {
    &.text-field,
    &.date-field {
      margin-bottom: 20px;
      > div > label {
        font-size: 12px;
        text-transform: uppercase;
      }
      > div > div {
        padding: 9.5px 15px;
      }
    }
    &.unread-messages-checkbox {
      > div {
        width: 250px !important;
        min-height: 16px;
        height: min-content;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        word-wrap: normal;
      }
      .MuiFormControlLabel-root {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .MuiOutlinedInput-input {
      padding: 9.5px 4px;
      width: 100%;
    }
    .MuiAutocomplete-tag {
      width: 70%;
    }
    .MuiInput-root {
      padding: 0 !important;
    }
    &__divider {
      margin-bottom: 24px;
      margin-top: 24px;
      background-color: #ebebee;
    }
    &__checkbox-group {
      flex-direction: column;
      background-color: $lightGray;

      .MuiAccordion-root {
        box-shadow: unset;
        background-color: $lightGray;
      }
      .MuiAccordionSummary-root {
        padding: 0;
        min-height: 0;
      }
      .MuiFormControlLabel-root {
        padding: 0;
      }

      .MuiAccordionDetails-root {
        padding-left: 0;
        flex-direction: column;
      }
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
    &__clear-all-filters-btn {
      color: $accentDark;
      padding: 0;
      font-size: 14px;
    }
    &__restore-filters-btn {
      color: $accentDark;
      padding: 0;
      font-size: 14px;
    }
  }
}

.feature-dashboard {
  margin-top: 32px;
  &__quick-filter-grid {
    margin-bottom: $spaceHuge2;
    &-item {
      width: 18%;
      cursor: pointer;
      border-radius: 8px;
      box-shadow: 4px 8px 16px rgba(28, 48, 75, 0.08);
      padding: 8px 20px 16px 20px;
      margin-right: 32px;
      position: relative;
      margin-bottom: 8px;
      &:last-child {
        margin-right: 0;
      }
      @include for_breakpoint(full-hd) {
        width: 14%;
      }
      &__number {
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        font-family: 'Roboto', sans-serif;
        &__zero {
          color: $adeccoPassive;
        }
        &__non-zero {
          color: $primaryBrandRed;
        }
      }
      &__title {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        line-height: 18px;
        color: $darkGray;
        font-weight: 400;
      }
      &__description {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #968c83;
      }
      &__close-icon {
        position: absolute;
        top: 12px;
        right: $spaceMedium;
      }
      &--selected {
        background-color: $primaryBrandRed;
        color: white;
        p {
          color: white;
        }
      }
    }
  }
}

.onboarding-created-at-interval {
  width: 177px;
  margin-right: 16px;
}

.onboarding-status {
  display: flex;
  justify-content: space-between;
  &__label {
    border-radius: 16px;
    background-color: $adeccoIce;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
  }
}

.admin-table-search {
  &.tag-ds input[type='text'] {
    background-color: $lightGray;
    border: none;
  }
  .material-icons {
    color: $neutral;
  }
}

.admin-table-buttons {
  background: none;
  color: $skyBlue;
  min-width: 0;
  .material-icons {
    margin-left: 8px;
  }
  &:hover {
    background: none;
  }
  &:disabled {
    color: #adadb5;
    background: none;
  }
}

.admin-export-options {
  list-style-type: none;
  white-space: nowrap;
  width: max-content;
}

.admin-dropdown-menu {
  width: auto !important;
  max-height: 600px !important;
}
