@import '../../../../styles/ootheme/variables';

$link: $darkRed;
$linkHover: $darkRed;
.flexHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.justifyEnd {
  justify-content: flex-end;
}
.flexGrow {
  flex-grow: 1;
}
.inlineFlexHolder {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.bottomDotted {
  border-bottom: 1px dotted #444;
}
.clientOrganization {
  background-color: #ddd;
  margin: 12px 0 12px;
  padding: 6px 12px;
  border-radius: 8px;
}
.clientOrganizationLabel {
  font-weight: 700;
}
.clientOrganizationId {
  color: $link;
  cursor: pointer;
}
.clientOrganizationConfigs {
  padding-left: 24px;
}
.clientOrganizationConfig {
  color: $link;
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
}
.clientOrganizationConfigList {
  margin: 0;
  padding: 0;
}
.clientOrganizationConfigItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -32px;
}
.clientOrganizationConfigItem > *:first-child {
  padding-right: 4px;
  flex-basis: 28px;
  flex-shrink: 0;
  text-align: right;
}
.clientOrganizationConfigItem > *:nth-child(2) {
  flex-grow: 1;
  font-weight: 700;
}
.impExpHolder {
  margin: 8px 0 2px 0;
}
.otherTenant {
  background-color: #fdd;
}
.noConfigs {
  color: #999;
}
.linkButton {
  color: $link;
  cursor: pointer;
}
.linkButton:hover {
  color: $linkHover;
  text-decoration: underline;
}
.linkButtonDisabled {
  opacity: 0.5;
  cursor: default;
}
.uploadButton,
.simpleButton {
  display: inline-block;
  width: 100%;
  padding: 2px 16px;
  min-width: 120px;
  max-width: 210px;
  height: 28px;
  position: relative;
  background-color: $primary;
  border-radius: 2px;
  cursor: pointer;
  vertical-align: text-bottom;
}
.mini {
  width: auto;
  min-width: 30px;
  padding: 2px;
}
.uploadButtonInput {
  display: block;
  opacity: 0.01;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0 none;
  outline: 0 none;
  width: 100%;
  height: 100%;
  background-color: $primary;
  cursor: pointer;
  z-index: 1;
}
.uploadButtonLabel,
.simpleButtonLabel {
  display: block;
  margin: 0 auto;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.4rem;
  text-align: center;
  cursor: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
}
.simpleButton {
  background-color: $secondary;
  width: 100%;
}
.uploadButton::after,
.simpleButton::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  z-index: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}
.uploadButton:hover::after,
.simpleButton:hover::after {
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color ease-in 250ms;
}
.simpleButtonDisabled,
.simpleButtonDisabled::after,
.simpleButtonDisabled:hover::after {
  background-color: #ddd;
  cursor: default;
}
.simpleButtonOutlined {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid $secondary;
  color: $secondary;
}
.simpleButtonOutlined .simpleButtonLabel {
  color: $secondary;
}
.simpleButton.simpleButtonOutlined:hover::after {
  background-color: rgba(255, 255, 255, 0.9);
}
.overlay {
  color: #000;
  font-size: 55vw;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0.1;
}
.assetHolder {
  background-color: #ccc;
  margin: 8px -4px 2px -8px;
  padding: 8px;
  border-radius: 5px;
}
.assetHeader {
  cursor: pointer;
}
.assetBox {
  margin: 16px 0 0;
}
.assetDir {
  margin: 8px 0 0;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #ddd;
}
.tenantAssetDir {
  margin: 8px 0 0;
}
.assetListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 2em;
}
.assetLabel {
  cursor: pointer;
  color: $link;
  font-size: 0.85em;
}
.assetDownloadLink {
  color: $link;
  text-decoration: none;
}
.clientOrganizationId:hover,
.clientOrganizationConfig:hover,
.assetLabel:hover,
.assetDownloadLink:hover {
  text-decoration: underline;
  color: $linkHover;
}
.assetPanel {
  margin: 16px 0;
  min-width: 360px;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.assetPanel::before {
  display: block;
  content: '';
  position: absolute;
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
  background-color: #bbb;
  background-image: linear-gradient(45deg, #ddd 25%, transparent 25%, transparent 75%, #ddd 75%, #ddd),
    linear-gradient(-45deg, #ddd 25%, transparent 25%, transparent 75%, #ddd 75%, #ddd);
  background-size: 8px 8px;
  background-repeat: repeat;
  background-position: 0 0;
  transform: rotate(45deg) translate(-22%, -22%);
  z-index: -1;
  padding-top: 200%;
}
.assetImage {
  object-fit: contain;
  display: block;
}
.textInput {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.translationsMissing {
  background-color: #ddd;
  margin: 12px 0 12px;
  padding: 6px 12px;
  border-radius: 8px;
}
