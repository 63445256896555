@import './../../../../../styles/mixins';

.form {
  display: flex;
  flex-flow: column;
  gap: 20px;
  grid-column: 1/8;
  @include for_breakpoint(full-hd) {
    max-width: 416px;
    width: 100%;
    margin-right: 120px;
  }
}

.submit {
  display: flex;
  justify-content: flex-end;
}
