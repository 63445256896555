.registration-button {
  background-color: #da291c;
  border-radius: 10px;
  width: 90%;
  margin-left: 5%;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  &__text {
    span {
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
    }
  }
}

.header-text-container {
  margin-top: 40px;
  padding-left: 5%;
  padding-right: 5%;
  font-style: normal;
  font-size: 26px;
  line-height: 29px;
  color: #273446;
  &__header {
    font-family: 'HouschkaHead';
    font-weight: 600;
  }
  &__subheader {
    font-family: 'HouschkaMedium';
    font-weight: 400;
  }
}
