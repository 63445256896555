// react-dates library

.client-day-picker {
  &__button {
    border-radius: 24px;
    background-color: $adeccoPink;
    color: $adeccoRed;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    svg {
      font-size: 17px;
      color: $adeccoRed;
    }
    cursor: pointer;
    &--left {
      left: -40px;
      top: 140px;
    }
    &--right {
      right: -40px;
      top: 140px;
    }
    &--disabled {
      display: none;
    }
  }
  &__description-dot {
    background-color: $adeccoPink;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 5px;
  }
  &__custom-active-mark {
    background-color: $adeccoPink;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  // Lib specific
  .CalendarMonth_caption {
    color: $adeccoTwilight;
    font-family: Merriweather, sans-serif;
    font-weight: 900;
    font-size: 18px;
  }
  .CalendarDay__default {
    border: 0;
    color: $adeccoTwilight;
    font-family: Merriweather, sans-serif;
    font-weight: 900;
    font-size: 14px;
  }
  .DayPickerNavigation__horizontal {
    display: flex;
    justify-content: space-between;
  }
  .CalendarMonth_caption {
    color: $adeccoTwilight;
    font-family: Merriweather, sans-serif;
    font-weight: 900;
    font-size: 18px;
  }
  .CalendarDay__default {
    border: 0;
    color: $adeccoTwilight;
    font-family: Merriweather, sans-serif;
    font-weight: 900;
    font-size: 14px;
    &:hover {
      background: $adeccoPink;
      border-radius: 50%;
    }
    &:focus {
      outline: none;
    }
  }

  .CalendarDay__selected_span,
  .CalendarDay__selected {
    background: $adeccoPink;
    border-radius: 50%;
  }
  .DayPicker_transitionContainer__vertical {
    // height: auto !important;
  }
  .DayPicker_weekHeader_li {
    color: $adeccoMidnight;
    font-family: Nunito, sans-serif;
    font-weight: 900;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    // background-color: $adeccoPink;
    background-color: $adeccoRoyal;
    color: white;
  }
  .CalendarDay__blocked_out_of_range {
    color: $adeccoCloud;
    &:hover {
      background: none;
      cursor: not-allowed;
    }
  }
  .DayPicker_weekHeader__verticalScrollable {
    border-bottom: 0;
  }
  .DayPicker {
    & ::-webkit-scrollbar {
      width: 4px;
    }

    & ::-webkit-scrollbar-thumb {
      background: $dust;
    }
  }
  .DayPickerNavigation_button__disabled {
    display: none;
  }

  // filled fields modifier
  &--filled-fields {
    .CalendarDay__default {
      border: 0;
      border-radius: 0;
      &:hover {
        border-radius: 0;
        background-color: $adeccoRoyal;
        color: white;
      }
    }
    .CalendarDay__selected_start {
      border-radius: 50% 0 0 50%;
    }
    .CalendarDay__selected,
    .CalendarDay__selected_span {
      background-color: $adeccoRoyal;
      color: white;
    }
    .CalendarDay__selected_start_no_selected_end {
      border-radius: 50%;
    }
    .CalendarDay__selected_end {
      border-radius: 0 50% 50% 0;
    }
    .CalendarDay__selected_end_no_selected_start {
      border-radius: 50%;
    }
  }
}
