@import './theme1/app-dialog';
@import './theme1/journey-page';
@import './theme1/app-steps-page';
@import './theme1/app-steps-progress-bar';
@import './theme1/app-messages-page';
@import './mixins';
@import './_configurationStyles';

.app {
  @import './theme1/variables';
  @import './theme1/main-template';
  @import './theme1/header';
  @import './theme1/client-stepper';
  @import './theme1/wrappers';
  @import './theme1/success-screen';
  @import './theme1/app-bottom-box-fixed';
  @import './theme1/feature-onboarding';
  @import './theme1/circular-progress';
  @import './theme1/feature-onboarding-home';
  @import './theme1/feature-registration-page-email';
  @import './theme1/location-employment-type';
  @import './theme1/components';
  @import './theme1/upload-documents';
  @import './theme1/app-loginSignin';
  @import './theme1/app-pre-register';
  @import './theme1/footer';
  @import './theme1/form-driver';

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;

  .app-textarea {
    width: 100%;
    padding: 13px 18px 30px 16px;
    border-color: $adeccoIce;
    border-width: 2px;
    border-radius: 8px;

    &--error {
      border-color: red;

      &::placeholder {
        color: red;
      }
    }
  }

  .grey-text {
    color: $adeccoGray;
  }

  .MuiTablePagination-selectIcon {
    top: calc(50% - 12px) !important;
    color: rgba(0, 0, 0, 0.54) !important;
    right: 0 !important;
    position: absolute !important;
    pointer-events: none !important;
    margin-right: 0 !important;
  }

  // Any .app child won't have a scrollbar
  // except those which are children of MuiTextField
  *:not(.MuiTextField-root *) > ::-webkit-scrollbar {
    display: none;
  }

  .visible-scroll {
    ::-webkit-scrollbar {
      display: block !important;
    }
  }

  .checkbox-container {
    & > .MuiFormControlLabel-label {
      flex: 1;

      & a {
        text-transform: none;
      }
    }
  }

  .toggle-container {
    & > label {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.MuiAutocomplete-root > input {
  border: none !important;
}
