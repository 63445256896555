@import './variables';

@mixin nunito($fontSize, $lineHeight, $fontWeight) {
  font-family: 'Nunito', sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  color: $black;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin nunito-light($fontSize, $lineHeight) {
  @include nunito($fontSize, $lineHeight, 300);
}

@mixin nunito-regular($fontSize, $lineHeight) {
  @include nunito($fontSize, $lineHeight, 400);
}

@mixin nunito-semi-bold($fontSize, $lineHeight) {
  @include nunito($fontSize, $lineHeight, 600);
}

@mixin nunito-bold($fontSize, $lineHeight) {
  @include nunito($fontSize, $lineHeight, 900);
}

@mixin houschkaHead($fontSize, $lineHeight, $fontWeight) {
  @font-face {
    src: local('HouschkaHead'), local('HouschkaHead-Medium'), url('/fonts/HouschkaHead-Medium.otf') format('opentype');
    font-family: 'HouschkaHead', sans-serif;
  }
  font-family: 'HouschkaHead', sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin houschkaHead-bold($fontSize, $lineHeight) {
  @font-face {
    src: local('HouschkaHead'), local('HouschkaHead-Bold'), url('/fonts/HouschkaHead-Bold.otf') format('opentype');
    font-family: 'HouschkaHead', sans-serif;
  }
  font-family: 'HouschkaHead', sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin houschkaHead-medium($fontSize, $lineHeight) {
  @font-face {
    src: local('HouschkaHead'), local('HouschkaHead-Medium'), url('/fonts/HouschkaHead-Medium.otf') format('opentype');
    font-family: 'HouschkaHead', sans-serif;
  }
  font-family: 'HouschkaHead', sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin houschkaHead-light($fontSize, $lineHeight) {
  @font-face {
    src: local('HouschkaHead'), local('HouschkaHead-Light'), url('/fonts/HouschkaHead-Light.otf') format('opentype');
    font-family: 'HouschkaHead', sans-serif;
  }
  font-family: 'HouschkaHead', sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.h1 {
  @include houschkaHead-medium(30px, 1.33);
  letter-spacing: 0.4px;
}

.h2 {
  @include houschkaHead-medium(21px, 1.43);
}

.h3 {
  @include nunito-light(18px, 1.5);
}

.h4 {
  @include nunito-semi-bold(16px, 1.38);
}

.input-idle {
  @include houschkaHead-bold(20px, 1);
  color: $adeccoGray;
}

.input-filled {
  @include houschkaHead-bold(20px, 1);
  color: $black;
}

.body-default-emphasis {
  @include nunito-semi-bold(16px, 1.5);
}

.input-default {
  @include nunito-light(16px, 1.44);
}

.body-default {
  @include nunito-light(16px, 1.5);
}

.body-small-emphasis {
  @include nunito-semi-bold(14px, 1.29);
}

.body-small-secondary {
  @include nunito-light(14px, 1.29);
}

.error-message {
  color: #f44336;
  margin: 0;
  font-size: 10px;
  font-style: normal;
  margin-top: 3px;
  text-align: left;
  font-family: Nunito, sans-serif;
  font-weight: bold;
  line-height: 1.29;
  font-stretch: normal;
  letter-spacing: 0.2px;
}
