@import 'variables';

.MuiDialog-root {
  background: green;
}

.header__under-menu-text {
  color: $primaryDark;
  display: flex;
  margin-top: $spaceLarge1;
  margin-bottom: $spaceLarge1 !important;
  align-items: center;
}

.MuiDialog-scrollPaper {
  display: block !important;
}

.under-menu {
  width: 100%;

  background: $dust;
  display: flex;
  flex-direction: column;
  padding: $spaceMedium;
  border-radius: $borderRadiusMedium;
  margin-top: -$spaceMedium;

  .MuiSelect-select {
    background: $white !important;
    color: $darkGray !important;
    padding: $spaceMediumLarge;
  }

  .chooseLanguage {
    font-weight: normal;
    font-size: $textSizeSubtitleSecondary;
    font-family: $nunito;
  }

  .logOut {
    margin-top: $spaceLarge2;
    font-weight: bolder;
    font-family: $nunito;
    font-size: $textSizeSubtitleSecondary;
    display: inline-flex;
    align-items: center;
  }
}

.header {
  border-radius: 0 0 8px 8px;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: $white;

  &__container {
    svg {
      cursor: pointer;
    }
  }

  &__under-menu {
    width: 100%;
    background: $dust;
    display: flex;
    flex-direction: column;
    padding: $spaceMedium;
    border-radius: $borderRadiusMedium;
    margin-top: -$spaceMedium;

    .MuiSelect-select {
      background: $white !important;
      color: $darkGray !important;
      padding: $spaceMediumLarge;
    }

    .header__under-menu-text {
      color: $primaryDark;
      display: inline-flex;
      margin-top: $spaceLarge1;
      margin-bottom: $spaceLarge1;
    }

    .chooseLanguage {
      font-weight: normal;
      font-size: $textSizeSubtitleSecondary;
      font-family: $nunito;
    }

    .logOut {
      margin-top: $spaceLarge2;
      font-weight: bolder;
      font-family: $nunito;
      font-size: $textSizeSubtitleSecondary;
      display: inline-flex;
      align-items: center;
    }
  }

  &__subcontainer {
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    @include for_breakpoint(tablet) {
      padding-left: 0;
      padding-right: 0;
    }

    & > div {
      flex: 1;
      align-content: center;
      box-shadow: none;
      display: grid;
      grid-template-columns: 1fr auto;
      @include for_breakpoint(tablet) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__iconcontainer {
    display: flex;
    align-items: center;
    padding-top: 26px;
    padding-bottom: 26px;
    @include for_breakpoint(tablet) {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  &__message-number {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    background-color: $adeccoRed;
    border-radius: $borderRadiusMedium;
    color: $white;
  }

  &__arrow-icon {
    margin-left: $spaceSmall;
    margin-right: $spaceSmall;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: $spaceSmall;
  }

  &__title_container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  &__select-language-box {
    width: 70px;
    margin-right: 5%;

    div {
      background-color: transparent;

      select,
      select:hover,
      .MuiOutlinedInput-input {
        background-color: white !important;
        color: black !important;
      }

      svg {
        fill: black;
      }
    }
  }

  li {
    padding: 4px 0 !important;
  }

  &__menu {
    gap: 54px;
    display: flex;
    @include for_breakpoint(tablet) {
      display: grid;
      gap: 0;
    }
  }

  &__clickable {
    cursor: pointer;
    color: #5c6573;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 10px;

    .material-icons {
      font-size: 14px;
    }
  }

  &__backbutton {
    display: flex;
    padding: $spaceMedium 0;
    cursor: pointer;
    flex-grow: 0;
  }

  &__background--gray {
    background-color: $adeccoSecondaryGrayLight;
  }

  &__link {
    &,
    &:hover,
    &:active,
    &:visited {
      color: $primaryDark;
      text-decoration: none;
    }
  }
}
