.editorWrapper {
  margin-top: 8px;
  margin-bottom: 16px;
  background-color: white;
  position: relative;
  border: 1px solid #1c304b;
  border-radius: 8px;
  min-height: 238px;
  width: 320px;
  p {
    margin: 0;
  }
}

.contentEditable {
  padding: 16px;
  position: relative;
  [data-slate-placeholder='true'] {
    top: 16px !important;
  }
}

.editorOptions {
  &__container {
    border-bottom: 1px solid #ebebee;
    padding: 8px;
    display: flex;
    gap: 4px;
  }
  &__item {
    color: #5c6573;
    cursor: pointer;
    padding: 7px;
    display: inline-flex;
    border-radius: 4px;
    transition: background-color 100ms ease;
    vertical-align: middle;
    border: 1px solid transparent;
    box-sizing: border-box;

    & > span {
      font-size: 24px;
    }
    &Active {
      background-color: #ebebee;
    }
    &:hover {
      border: 1px solid #009acc;
    }
  }
}

.link {
  &__wrapper {
    position: absolute;
    position: absolute;
    top: 42px;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &__popup {
    background-color: white;
    padding: 16px;
  }
}

.textStyle {
  &__dropdown {
    position: absolute;
    background: white;
    width: 200px;
    top: 45px;
    z-index: 2;
    border-radius: 8px;
    box-shadow: 4px 2px 16px 0px rgba(28, 48, 75, 0.08);
  }
  &__item {
    padding: 8px 16px;
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    &-icon {
      font-size: 16px;
      margin-right: 8px;
    }
    &-p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &-h1 {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }
    &-h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    &-h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #5c6573;
    }
  }
}
