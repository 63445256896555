.client-map-with-info {
  border: 2px solid $adeccoIce;
  border-radius: 8px;
  position: relative;
  &__title {
    position: absolute;
    left: 24px;
    top: 24px;
    z-index: 2;
  }
  &__description {
    position: absolute;
    left: 15px;
    bottom: 15px;
    right: 15px;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 8px;
    z-index: 2;
    padding: 18px;
  }
  &__timezone {
    color: $adeccoPassive;
  }
  &__map {
    width: 100%;
    height: 300px;
  }
  &__location-name {
    color: $adeccoTwilight;
    font-size: 18px;
    margin-bottom: 4px;
  }
}
