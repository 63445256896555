@use 'src/styles/variables' as *;

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icon-container {
    background-color: white;
    z-index: 2;
  }

  &__icon {
    border: 1px solid $text01;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    line-height: 0;
    cursor: pointer;
    &:hover {
      border-color: #009acc;
    }
  }
  span {
    font-size: 16px;
  }
}

.item {
  margin-bottom: 8px;
  border-radius: 8px;
  position: relative;
}

.condition {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    &:after {
      content: '';
      background-color: $bordersDividers01;
      height: 1px;
      position: absolute;
      left: 0;
      right: 90px;
    }

    &-title {
      background-color: white;
      z-index: 2;
      padding-right: 16px;
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border-radius: 8px;
  padding: 40px;
  z-index: 4;
}
