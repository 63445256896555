.timelineItem {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
    background-color: white;
    border-radius: 8px;
}

.date {
    font-size: 14px;
    margin-bottom: 5px;
}

.avatar {
    border-radius: 50%;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #ffffff;
    margin-right: 10px;
    width: 40px;
    height: 40px;
}

.details {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 16px;
    color: #333333;
}

.name {
    font-size: 14px;
    color: #666666;
}
