.tooltip {
  background-color: red;
}

.base {
  background: none !important;
  border: none !important;
  display: flex;
  align-items: center !important;
  span {
    font-size: 16px;
    color: #1c304b;
  }
}

.labelWithTooltip {
  display: flex;
  align-items: center;
}
