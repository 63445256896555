.wrapper {
  position: relative;
}

.disabled {
  border-left: 2px solid #0000;
  color: #adadb5;
  pointer-events: none;
}

.icon {
  font-size: 16px;
  left: -24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.arrowActive {
  transform: rotate(180deg);
  transition: all 100ms ease;
}
