@import "variables";

.admin-metrics-page {
  background-color: $dust;

  &__header {
    background-color: $adeccoRed;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__title {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: $spaceMedium;
    background-color: $white;
    align-items: center;

    & > img {
      height: 50%;
    }
  }

  &__filter {
    display: flex;
    flex: 1;

    & > div {
      width: 50%;
      margin: $spaceSmall;
    }

    & > button {
      height: 32px;
    }

    &:not(:first-child) {
      justify-content: flex-end;
      align-items: center;
    }

    & > a {
      text-decoration: none;
    }
  }
}
