.feature-admin-import {
  min-height: calc(100vh - 80px);
  &__container {
    min-height: calc(100vh - 80px);
  }
  &__left-side {
    box-shadow: 0 4px 16px rgba(28, 48, 75, 0.1);
    padding: 32px;
    @include for_breakpoint(full-hd) {
      width: 328px;
      flex-basis: auto;
    }
  }
  &__right-side {
    padding: 32px 8px 32px 32px;
    position: relative;
    max-width: 75%;
    .tag-ds .button-secondary_small,
    .tag-ds .button-secondary_small_loading,
    .tag-ds .button-secondary_small_selected,
    .tag-ds .button-secondary_small_success,
    .tag-ds .button-secondary_success {
      min-width: 24px;
      padding: 8px;
    }
    @include for_breakpoint(full-hd) {
      padding-left: 120px;
      max-width: calc(100% - 328px);
    }
  }
  &__loader {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__configuration-item {
    border: 1px solid #ccccd3;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
  }

  &__table-field {
    position: relative;
  }

  &__filter-list {
    width: 400px !important;
  }

  &__dropdown {
    border: 1px solid black;
    width: auto;
    border-radius: 8px;
    vertical-align: middle;
    padding: 0 10px 0 16px;
  }

  &__arrow-down {
    vertical-align: middle;
  }

  &__filter-button {
    max-height: initial !important;
    height: 100%;
    line-height: 23px !important;
    padding: 7px 0px;
  }
  .scrollConfigList .checkbox-group-container {
    height: 75vh;
    overflow-y: scroll;
  }
}


