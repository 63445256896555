@use 'src/styles/variables' as *;

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $backgrounds02;
  height: 100%;
}

.container {
  width: 420px;
}

.description {
  margin-bottom: 32px;
  color: $text02;
}

.btn {
  display: inline-block;
}
