@import "variables";
.assignee-card {
  border-radius: 10px;
  border: 2px solid $adeccoIce;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  min-height: 80px;
  background-color: $white;
  &--disabled {
    background-color: $white;
    .assignee-card__icon {
      svg {
        background-color: $adeccoPassive;
      }
    }
  }

  &__avatar {
    margin-right: 8px;
    width: 56px;
    height: 56px;
  }
  &__icon {
    margin-left: auto;
    line-height: 1;
    svg {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $adeccoRoyal;
      color: white !important;
    }
  }
}
