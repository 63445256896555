@import "variables";

.header {
  background-color: white;
  height: 80px;
  border-radius: 0 0 8px 8px;
  margin: 0 auto;
  z-index: 999;
    
  &--shadow {
    filter: drop-shadow(0px 4px 8px $adeccoShadow);
  }
  
  &__container {
    max-width: 600px;
    height: 100%;
    svg {
      cursor: pointer;
    }
  }
  &__subcontainer {
    height: 100%;
  }
  &__arrow-icon {
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
  }
  &__title_container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-right: 80px;
  }
}
