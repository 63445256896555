.bottom-box-fixed {
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 600px;
  margin: 0 auto;
  padding: 34px;
  background-color: white;
  box-shadow: 0px -4px 4px 0px rgba(150,140,131,0.3);
  &__button-next {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  &__time-counter-box {
    top: 50%;
    transform: translateY(-50%);
    &__time-left {
      z-index: 1;
    }
    &__time-left-background {
      position: absolute;
      z-index: 0;
      color: rgba(140, 140, 148, 0.2);
    }
  }
}
