tbody td:first-child {
  min-width: 32px !important;
}

.tag-ds .button-secondary,
.tag-ds button.button-secondary {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.tag-ds .data-table-wrapper .data-table-action-bar {
  padding: 8px 8px 8px 24px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.tag-ds .data-table-wrapper .data-table-action-bar .data-table-items-selected,
.tag-ds.data-table-wrapper .data-table-action-bar .data-table-items-selected {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  flex-grow: 1 !important;
  position: relative !important;
}

.tag-ds .data-table-wrapper .data-table-action-bar .data-table-items-selected .admin-table-buttons,
.tag-ds.data-table-wrapper .data-table-action-bar .data-table-items-selected .admin-table-buttons {
  background-color: var(--backgrounds-03, #ebebee) !important;
  display: inline-block !important;
  position: absolute !important;
  right: 0 !important;
  top: -3px !important;
}

.tag-ds .data-table-wrapper .data-table-action-bar {
  gap: 8px !important;
  padding: 8px 8px 8px 24px !important;
}

.tag-ds .data-table-wrapper .data-table-action-bar .data-table-action-items,
.tag-ds.data-table-wrapper .data-table-action-bar .data-table-action-items {
  gap: 2px !important;
}

.tag-ds .data-table-wrapper .data-table-action-bar .data-table-action-items *,
.tag-ds.data-table-wrapper .data-table-action-bar .data-table-action-items * {
  margin-right: 0 !important;
}

.tag-ds .data-table-wrapper .data-table-action-bar .data-table-action-items button::before,
.tag-ds.data-table-wrapper .data-table-action-bar .data-table-action-items button::before {
  content: '' !important;
  display: block !important;
  height: 24px !important;
  width: 0 !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto 0 !important;
  border-right: 1px solid var(--borders-dividers-02, #a2b3c9) !important;
}

.tag-ds .data-table-wrapper .data-table-action-bar .data-table-action-items :last-child::before,
.tag-ds.data-table-wrapper .data-table-action-bar .data-table-action-items :last-child::before,
.tag-ds .data-table-wrapper .data-table-action-bar .data-table-action-items button:last-child::before,
.tag-ds.data-table-wrapper .data-table-action-bar .data-table-action-items button:last-child::before {
  content: none !important;
  background: none !important;
}

.admin-pagination {
  margin: 32px 0;
}

.admin-pagination .input-wrapper {
  margin-top: 0 !important;
}

.admin-pagination .paginator {
  margin-left: 32px;
  margin-right: 16px;
}

.dropdown-menu {
  z-index: 3 !important;
}
