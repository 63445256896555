.paginator {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0 24px;
}

.paginator-pages {
  display: flex;
  justify-content: flex-end;
  gap: 0 8px;
}

.paginator-page {
  display: inline-block;
  padding: 0 3px;
  min-width: 24px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid transparent;
}

.paginator-page:hover {
  border-color: var(--backgrounds-accent);
}

.paginator-page-selected {
  cursor: default;
  background-color: var(--backgrounds-accent);
}

.paginator-offsets {
  padding: 4px 40px 4px 12px !important;
}

.paginator-offsets-icon {
  margin-right: 4px;
  right: 4px;
}
