.angled-edge-shape {
  width: 140%;
  height: 616px;
  margin-left: -20%;
  margin-top: 10%;
  background: linear-gradient(103.18deg, #da291c 0%, #bf0d3e 100%);
  transform: rotate(-4deg);
}

.angled-edge-container {
  width: 100%;
  height: 100%;
  background: transparent;
}
