@use 'src/styles/variables' as *;

.initialBox {
  background-color: white;
  margin-top: 16px;
  padding: 24px;
  border-radius: 8px;

  &__icon {
    border: 1px solid $text01;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    line-height: 0;
    cursor: pointer;
    background-color: white;
    z-index: 2;
    font-size: 16px;
    &--disabled {
      border-color: #ced7e4;
      cursor: not-allowed;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  span {
    font-size: 16px;
  }
}

.container {
  background-color: white;
  padding: 32px;
  margin-bottom: 36px;
  gap: 8px 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.csvInput {
  height: 200px;
  margin-bottom: 16px;
}

.option {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &__container {
    width: 100%;
    border-radius: 8px;
    padding: 8px;
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border-radius: 8px;
  padding: 40px;
  z-index: 4;
}

.item {
  &__icon-container {
    display: flex;
    gap: 0px 8px;
    align-items: center;
    position: relative;
  }
  &__icon {
    color: $textInteractiveLinkOnLightEnabled;
    font-size: 16px;
    cursor: pointer;
  }
}

.selectedOptionsList {
  padding: 8px;
  border: 1px solid $text01;
  border-radius: 8px;
  &__container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
  }
}

.button {
  cursor: pointer;
  color: $textInteractiveLinkOnLightEnabled;
  display: flex;
  align-items: center;
  align-items: center;
  font-weight: 500;
  gap: 8px;
  margin-top: 16;
  &--disabled {
    color: gray;
  }
}

.dndDescription {
  font-weight: bold;
  color: $text02;
  margin-bottom: 8px;
  display: inline-block;
}

.fullListBtn {
  color: $textInteractiveLinkOnLightEnabled;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.disabled {
  color: red;
}
