@import '../../../../styles/mixins';

.candidateProfilePage {
  display: grid;
  grid-template-columns: 312px 1fr;
  height: 100%;
}

.header {
  grid-column: 1 / 3;
  display: flex;
  border-bottom: 1px solid #ebebee;
}

.sidebar {
  box-shadow: 0 4px 16px rgba(28, 48, 75, 0.1);
}

.content {
  padding: 32px;
  display: grid;
  overflow: auto;
  @include for_breakpoint(full-hd) {
    padding-left: 120px;
  }
}

.notification {
  width: 392px !important;
  transform: unset !important;
  left: unset !important;
  right: 181px !important;
  top: 80px !important;
}
