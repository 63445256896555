.candidateProfileSidebar {
  box-shadow: 0 4px 16px rgba(28, 48, 75, 0.1);
}
.drawer {
  z-index: 0 !important;
}
.candidateProfileSidebar > div {
  min-height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.candidateProfileSidebar li {
  padding-left: 0 !important;
}
.hover {
  cursor: pointer;
}

.pages {
  display: grid;
  grid-auto-rows: minmax(54px, 1fr);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  align-items: center;
}

.success > span {
  color: #73d85a;
}
.inProgress {
  font-weight: bold !important;
}
.hireStep {
  margin-top: 4rem !important;
}
.tree-view-element {
  white-space: normal;
}

.error > span {
  color: #db3736 !important;
}
