.signDocuments {
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  grid-column: 1/8;
  &__item {
    background-color: #f8f8f9;
    padding: 12px 24px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
}
