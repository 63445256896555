@import '../../../styles/mixins';

.MuiDialog-root {
  .MuiPaper-root.MuiDialog-paper,
  .MuiPaper-root-MuiDialog-paper {
    padding: 12px !important;
    max-width: 567px;
    @include for_breakpoint(desktop) {
      padding: 32px !important;
    }
  }
  .MuiButtonBase-root.MuiIconButton-root.closer {
    position: absolute;
    top: 32px;
    right: 32px;
  }
  .MuiTypography-root.MuiTypography-h6,
  .MuiTypography-root-MuiDialogTitle-root {
    padding-top: 8px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .MuiDialogContent-root {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .MuiDialogActions-root {
    .MuiButtonBase-root.MuiButton-root,
    .MuiButtonBase-root-MuiButton-root {
      padding: 8px 24px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include for_breakpoint(desktop) {
        max-width: 340px !important;
        width: auto !important;
        min-width: 140px !important;
      }
    }
  }
}
.MuiDialog-root {
  .MuiPaper-root.MuiDialog-paper,
  .MuiPaper-root-MuiDialog-paper {
    max-width: 615px;
  }
}
