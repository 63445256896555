// Colors
$primary: #da291c;
$secondary: #4a5666;
$white: #fff;
$black: #171717;
$red: #d3305d;
$darkRed: #7a0a24;
$lightGray: #9f9f9f;
$gray: #848281;
$blue: #00a9e0;
$error: #db3736;
$colorText: #273446;
$adeccoIce: #edeaf9;

// $adeccoGray: #968c83;
// $adeccodGrayLight: #c7ced6;
// $adeccodGrayLight2: #dbdbdb;
// $redGradient: linear-gradient(90deg, #782f40 0%, #da291c 68%, #da291c 100%);
// $adeccoRed: #db3736;
// $adeccoGray: #959eaa;
// $adeccoGray2: #c0c7d0;
// $adeccoGray10: rgba(150, 140, 131, 0.1);
// $adeccoGray20: rgba(150, 140, 131, 0.2);
// $adeccoGray40: rgba(150, 140, 131, 0.4);
// $adeccoCerise: rgb(191, 13, 62);
// $adeccoBlue: rgb(0, 169, 224);
// $adeccoBlue005: rgba(0, 169, 224, 0.05);
// $adeccoTwilight: #312068;
// $adeccoRoyal: #5b3bc0;
// $adeccoGreen: #44b02a;
// $adeccoShadow: rgba(199, 206, 214, 0.8);
// $dust: #f3f3f4;
// $adeccoCloud: #ccccd7;
// $darkGray: #4a5666;

// $adeccoRedShadow: #e82121;
// $primaryDark: #273446;
// $adeccoRose: #da292c;
// $adeccoPrimaryBlack: #273446;
// $adeccoYellow: #f2a900;
// $adeccoBlue: #00a9e0;
// $adeccoSecondaryGrayLight: #f1f3f6;

/* Text Size */
$textSizeTiny: 10px;
$textSizeBodySmall: 12px;
$textSizeBody: 14px;
$textSizeSubtitleSecondary: 16px;
$textSizeSubtitle: 18px;
$textSizeTitle: 24px;
$textSizeBigTitle: 26px;
$textSizeBodyBigger: 16px;
$textSizeLarge: 48px;

// Fonts

$roboto: 'Roboto', sans-serif;
$bold: 700;
$nunito: 'Nunito';

$spaceSmall: 8px;
$spaceMedium: 16px;
$spaceMediumLarge: 24px;
$spaceLarge1: 32px;
$spaceLarge2: 48px;
$spaceHuge1: 56px;
$spaceHuge2: 64px;

$borderRadiusSmall: 5px;
$borderRadiusMedium: 10px;
$borderRadiusLarge: 20px;

$boxShadowL3: 0px 4px 4px #dbdbdb;
$boxShadowL2: 0px 2px 4px #dbdbdb;
$boxShadowL1: 0px 1px 4px #dbdbdb;
$borderRadiusRound: 50%;

:export {
  primary: $primary;
  secondary: $secondary;
  white: $white;
  black: $black;
  red: $red;
  darkRed: $darkRed;
  lightGray: $lightGray;
  gray: $gray;
  blue: $blue;
  error: $error;
  colorText: $colorText;
}
