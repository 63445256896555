@import "variables";

.client-stepper {
  &__container {
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }

  &__line {
    border-top: 2px solid $adeccoRedSecond;
  }

  &__title-text {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #273446;
  }

  &__description-text {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #273446;
  }

  &__step {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &--done {
      width: 16px;
      height: 16px;
      background-color: #DA291C;
    }
    &--active {
      width: 22px;
      height: 22px;
      background-color: #DA291C;
      box-shadow: 0px 0px 10px #E82121;
    }
    &--hidden {
      width: 16px;
      height: 16px;
      border: 2px solid #959EAA;
    }
  }
}
