$media_queries: (
  'mobile': unquote('only screen and (max-width: 672px)'),
  'tablet': unquote('only screen and (max-width: 768px)'),
  'desktop': unquote('only screen and (min-width: 1024px)'),
  'macbookAir': unquote('only screen and (min-width: 1024px) and (max-width: 1280px)'),
  'wide-desktop': unquote('only screen and (min-width: 1440px)'),
  'ultra-wide-desktop': unquote('only screen and (min-width: 1024px)'),
  'full-hd': unquote('only screen and (min-width: 1920px)'),
);

@mixin for_breakpoint($breakpoints) {
  $conditions: ();
  @each $breakpoint in $breakpoints {
    // If the key exists in the map
    $conditions: append($conditions, #{inspect(map-get($media_queries, $breakpoint))}, comma);
  }

  @media #{$conditions} {
    @content;
  }
}
