@import './theme1/variables';
@import './theme2/variables';

// Used for preloading fonts for dashboard
@font-face {
  font-family: 'NunitoRegular';
  src: url('../fonts/Nunito/nunito-regular-webfont.woff2') format('woff2'),
    url('../fonts/Nunito/nunito-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSemiBold';
  src: url('../fonts/Nunito/nunito-semibold-webfont.woff2') format('woff2'),
    url('../fonts/Nunito/nunito-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoBold';
  src: url('../fonts/Nunito/nunito-bold-webfont.woff2') format('woff2'),
    url('../fonts/Nunito/nunito-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MerriweatherBlack';
  src: url('../fonts/Merriweather/merriweather-black-webfont.woff2') format('woff2'),
    url('../fonts/Merriweather/merriweather-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SignPainter';
  src: local('SignPainter-HouseBrush'), url('../fonts/SignPainterHouseScript.woff') format('opentype');
}

@font-face {
  font-family: 'Bradley Hand';
  src: local('bradhitc'), url('../fonts/bradhitc.ttf') format('opentype');
}

html,
body,
#root,
.app,
.client {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

.home-page {
  &__wrapper {
    width: 800px;
    margin: 0 auto;
    padding-top: 150px;
  }

  &__logo {
    text-align: center;
    margin-bottom: 50px;
  }

  &__controls {
    a {
      text-decoration: none;
    }
  }
}

.center-icon-verticaly {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.loader-centred {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.MuiPickersBasePicker-container {
  .MuiTypography-body1 {
    line-height: 1;
  }
}

.color-adeccoRoyal {
  color: $adeccoRoyal;
}

.color-adeccoTwilight {
  color: $adeccoTwilight;
}

//GOOGLE MAP Styles
.gmnoprint {
  display: none;
}

.empty-div {
  display: none;
}

.timePickerInputError {
  color: $adeccoRed;
}

.compact .MuiOutlinedInput-input {
  padding: 7px 14px;
}

//loader widget on Calendar, used only in MasterCalendar (it loads outsied of calendar component)
.b-mask-content {
  background-color: $adeccoRed;
}

.MuiDialog-root.multi-candidate-delete-modal,
.MuiDialog-root.edit-readonly-confirm-modal {
  .MuiPaper-root.MuiDialog-paper,
  .MuiPaper-root-MuiDialog-paper {
    padding: 32px !important;
    max-width: 595px;
  }
  .MuiButtonBase-root.MuiIconButton-root.closer {
    position: absolute;
    top: 32px;
    right: 32px;
  }
  .MuiTypography-root.MuiTypography-h6,
  .MuiTypography-root-MuiDialogTitle-root {
    padding-top: 8px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .MuiDialogContent-root {
    padding-right: 8px !important;
    padding-left: 8px !important;
    .dialog-form {
      margin-top: 16px;
    }
  }
  .MuiDialogActions-root {
    .MuiButtonBase-root.MuiButton-root,
    .MuiButtonBase-root-MuiButton-root {
      width: auto !important;
      min-width: 140px !important;
      max-width: 340px !important;
      padding: 8px 24px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.MuiDialog-root.edit-readonly-confirm-modal {
  .MuiPaper-root.MuiDialog-paper,
  .MuiPaper-root-MuiDialog-paper {
    max-width: 615px;
  }
}

@media screen and (max-width: 860px) {
  .MuiPickersModal-withAdditionalAction .MuiButton-root {
    min-width: 90px;
  }
}

@media screen and (max-height: 1000px) {
  .camera-selectbox {
    position: absolute;
    top: 77px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
