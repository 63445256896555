.adminPermissionDetails {
  &__container {
    height: 100%;
  }
  &__leftSide {
    box-shadow: 0 4px 16px rgba(28, 48, 75, 0.1);
    padding: 32px;
    position: relative;
    height: 100%;
  }
  &__rightSide {
    padding: 32px 32px 16px 32px;
    position: relative;
    height: 100%;
    &::-webkit-scrollbar {
      display: initial;
    }
  }
  &__saveButton {
    button {
      width: 100%;
    }
  }
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
.addButtonContainer {
  margin-right: 24px;
}
.topContainer {
  display: grid;
  grid-template-columns: auto 1fr 184px;
  gap: 24px;
}
.addButton {
  width: 100%;
}
.nestedListSelector {
  &__container {
    background-color: #f8f8f9;
    padding: 24px 32px;
    border-radius: 8px;
  }
}

.userPermissionDetailsPageContainer {
  height: 100%;
  align-items: center;
}

#searchBox {
  background-color: white;
}

.toolTipContainer {
  margin-left: 10px;
  color: #5c6573;
  font-size: 20px;
  position: relative;
  cursor: pointer;
}

.collapseButton {
  margin-top: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #107b9e;
  cursor: pointer;
}

.nestedList {
  height: calc(100% - 190px);
  overflow: auto;
}

.nestedListParent {
  margin-bottom: 24px;
}

.checkboxLabel {
  word-wrap: break-word;
  line-height: 24px;
}

#childContainer {
  padding-left: 50px;
  margin-top: 4px;
  margin-bottom: 0;
}

.accordionContainer {
  .accordionHeader {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}
