@import 'variables';

.loginSignin {
  background: linear-gradient(193.41deg, $adeccoRedSecond 0%, $adeccoRedSecond 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: $spaceMedium;
  .generalButton {
    margin: $spaceSmall;
    border-radius: $textSizeTiny;
    margin-left: auto;
    margin-right: auto;
    padding: $spaceMedium;
    display: flex;
  }
  .signInButton {
    background: $white;
    color: $adeccoRedSecond;
    margin-top: $spaceLarge1;
    margin-bottom: $spaceLarge1;
    max-width: 150px;
  }
  .signUpButton {
    color: $white;
    border: solid;
    border-color: $white;
  }
  .adeccoLogo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: $spaceLarge1;

    & > * {
      max-height: 55px;
    }
  }
  &__select-language {
    display: flex;
    flex-direction: row-reverse;
    .header__select-language-box {
      margin-right: 0;
    }
  }
  &__main-content {
    margin-top: 30vh;
  }

  & > .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    border-top-color: $white;

    & > .footer__container > .footer__container-item {
      border-right-color: $white;
      & > a {
        color: $white;
      }
    }
  }
}
