@import 'src/styles/theme2/variables';

.footer {
  margin-left: $spaceMedium;
  margin-right: $spaceMedium;
  &__container {
    display: flex;
    margin-top: $spaceMedium;
    padding-bottom: $spaceMedium;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid $primaryDark;
  }
  &__container-item {
    margin: 0;
    padding-top: 10px;
    text-align: center;
    font-size: 10px;
    color: $primaryDark;
    font-weight: bold;
    & > a {
      color: $primaryDark;
      font-weight: bold;
    }
  }
  &__container-item:last-child {
    border-right: none;
    padding-right: 0;
  }
}
