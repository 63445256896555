@import "variables";

.shift-instance-card {
  background-color: $dust;
  padding: 24px;
  display: flex;
  align-items: center;
  .tag {
    margin-right: 16px;
  }
}
