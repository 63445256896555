@use 'src/styles/variables' as *;

.admin-reactive-select {
  color: red !important;
  .MuiFormLabel-root {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    letter-spacing: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #1c304b;
  }
  .MuiInput-root {
    font-size: 14px;
    .MuiSelect-select {
      padding: 8px 48px 8px 16px !important;
      line-height: 24px;
      max-height: 40px;
      box-sizing: border-box;
    }
    .MuiSelect-select:focus {
      background-color: #ffffff;
    }
    .MuiSvgIcon-root {
      fill: #1c304b;
    }
  }
}
