@import "variables";

.client-input-expandable {
  width: 20px;
  transition: all 300ms ease;
  display: flex;
  align-items: center;
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .MuiOutlinedInput-input {
    padding-right: 0;
  }
  &__icon {
    font-size: 25px;
    color: $adeccoRoyal;
  }
  &--active {
    width: 300px;
  }
}
