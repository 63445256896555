@import '../../../../../styles/mixins';

.candidateProfileSelectedPage,
.candidateProfileImportPage {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 4rem;
  @include for_breakpoint(full-hd) {
    display: flex;
  }
}
.candidateProfileSelectedPage > :only-child {
  grid-column: 1/8;
}
