.checkboxLabel {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
}

:global(.checkbox-container) {
  margin: 0;
  align-items: center;
  & > input {
    margin: 0 8px 0 0 !important;
  }
  .checkboxText {
    margin: 0 !important;
  }
}
