@import "variables";
.snackbar {
  color: $adeccoGreen2;

  & .MuiPaper-root {
    background-color: $white;
  }

  & .MuiAlert-message {
    color: $adeccoPrimaryBlack;
  }

  & .MuiAlert-action {
    & .MuiSvgIcon-root {
      color: $adeccoGray,
    }
  }

  .error{
    & .MuiAlert-icon {
      & .MuiSvgIcon-root {
        color: $adeccoRose,
      }
    }
  }

  .success{
    & .MuiAlert-icon {
      & .MuiSvgIcon-root {
        color: $adeccoGreen2,
      }
    }
  }

  .warning{
    & .MuiAlert-icon {
      & .MuiSvgIcon-root {
        color: $adeccoYellow,
      }
    }
  }

  .info{
    & .MuiAlert-icon {
      & .MuiSvgIcon-root {
        color: $adeccoBlue,
      }
    }
  }

}
