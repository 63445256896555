@use 'src/styles/variables' as *;

.activityHistory {
  &__item {
    background-color: white;
    margin-bottom: 8px;
    padding: 8px 16px;
    border-radius: 8px;
  }
  &__drawer {
    padding: 32px;
    flex: 1;
    height: 100%;
    background-color: $backgrounds02;
    z-index: 2;
    overflow: auto;
    width: 561px;
    &-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      p {
        margin: 0;
      }
      span {
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
  &__tabs {
    & > div {
      width: 100% !important;
      div {
        margin-left: auto;
        &:last-child {
          width: auto !important;
          & > button {
            width: auto !important;
          }
        }
      }
    }
  }
  &__filterItem {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin: 0;
    cursor: pointer;
  }
}
