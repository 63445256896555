@import 'variables';

.client-stepper {
  &__container {
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }

  &__line {
    border-top: 2px solid;
  }

  &__whiteline {
    border-top: 2px solid white;
  }

  &__title-text {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: $adeccoPrimaryBlack;
  }

  &__description-text {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: $adeccoPrimaryBlack;
  }

  &__step {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &--done {
      width: 16px;
      height: 16px;
      // background-color: $adeccoRedSecond;
    }
    &--active {
      width: 22px;
      height: 22px;
      // background-color: $adeccoRedSecond;
      // box-shadow: 0px 0px 10px;
    }
    &--hidden {
      width: 16px;
      height: 16px;
      border: 2px solid $adeccoGray;
    }
  }
}

.line-stepper {
  width: 100%;
  position: relative;

  &__bg-line {
    height: 8px;
    border-radius: 16px;
    width: 100%;
  }
  &__progress-line {
    height: 8px;
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
