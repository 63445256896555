.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  border: 1px solid black;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.partContainer {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
}
