.multiple-select {
  &__button {
    display: flex;
    justify-content: space-between;
    border-color: rgba(150, 140, 131, 0.4);
    &:hover {
      background-color: white;
      border-color: rgba(150, 140, 131, 0.4);
    }
  }
}
