// These variables are used for scss modules

// colors
$adeccoRed: #db3736;
$text02: #5c6573;
$backgrounds02: #f8f8f9;
$backgrounds01: #ffffff;
$text01: #1c304b;
$bordersDividers02: #ccccd3;
$textInteractiveLinkOnLightEnabled: #107b9e;
$bordersDividers01: #ebebee;
$boxShadow1: #1c304b14;
$textInteractiveLink_on_lightPressed: #004d66;
$accent: #da291c;
$accentLight: #e55c52;
$accentLighter: #f9a49e;
$accentDark: #952821;
$accentDarker: #310c08;
