.success-screen {
  background: linear-gradient(rgb(218, 41, 28), rgb(191, 13, 62));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  max-width: 600px;
  margin: 0 auto;
}
