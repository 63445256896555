@import './../../../styles/mixins';

.container {
  max-width: 98%;
  @include for_breakpoint(desktop) {
    max-width: 95%;
  }
  @include for_breakpoint(wide-desktop) {
    max-width: 85%;
  }
  @include for_breakpoint(full-hd) {
    max-width: 80%;
  }
}
