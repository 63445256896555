@use 'src/styles/variables' as *;

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.addNewControlBtn {
  border: 1px solid $bordersDividers01;
  padding: 16px;
  border-radius: 8px;
  &--small {
    margin-top: 12px;
    padding: 8px 16px;
  }
}

.list {
  overflow: scroll;
  max-height: 220px;
  &::-webkit-scrollbar {
    display: block !important;
  }
}

.listItem {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  &:hover {
    background-color: #f8f8f9;
    border-bottom-color: #ebebee;
  }
}

.listButton {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: flex-end;
  margin-bottom: 8px;
  cursor: pointer;
}
