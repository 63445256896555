.page-header {
  padding: 40px 0;
  position: relative;
  &__back-btn {
    display: inline-flex;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
  }
  &__close-icon {
    .MuiSvgIcon-root {
      color: $white;
    }
  }
  &__header-text-white {
    color: $white;
  }
  &__header-save-button {
    margin-left: 40px;
  }
}

.page-control-header {
  &__left-side {
    display: flex;
    align-items: center;
  }
}
