.heading {
  margin-bottom: 40px !important;
  text-align: center;
}

.select {
  margin-bottom: 48px;
  :global(.dropdown) {
    width: 100% !important;
  }
  *:not(.MuiTextField-root *) > ::-webkit-scrollbar {
    display: block !important;
  }
}

.button {
  margin: 0 auto;
  width: 100%;
}
