//Colors
$white: #ffffff;
$white40: rgba($white, 0.4);
$black: #000000;
$dust: #f3f3f4;
$adeccoPassive: #8c8c94;
$adeccoCloud: #ccccd7;
$adeccoRed: #db3736;
$adeccoRedSecond: #da291c;
$adeccoDarkRed: #730000;
$adeccoRed20: rgba($adeccoRed, 0.2);
$adeccoCerise: #9f2944;
$adeccoPlum: #6f2238;
$adeccoPink: #fdbac6;
$adeccoSlate: #414150;
$adeccoParrot: #00b398;
$adeccoMidnight: #06051e;
$adeccoTwilight: #312068;
$adeccoRoyal: #5b3bc0;
$adeccoDawn: #b5b4ea;
$adeccoIce: #edeaf9;
$adeccoMarigold: #feda45;
$adeccoSeafoam: #ccf0ea;
$adeccoTeal: #00b398;
$adeccoBlue: #00a9e0;
$adeccoGreen: #43b02a;
$adeccoPurple: #ad1aac;
$adeccoOrange: #e35205;
$adeccoYellow: #f2a900;
$adeccoGray: #968c83;
$adeccoSecondaryGray: #C0C7D0;
$adeccoSecondaryGrayLight1: #959EAA;
$adeccoGrey10: rgba($adeccoGray, 0.1);
$adeccoGrey20: rgba($adeccoGray, 0.2);
$adeccoGrey40: rgba($adeccoGray, 0.4);
$adeccoGrey60: rgba($adeccoGray, 0.6);
$adeccoGrey80: rgba($adeccoGray, 0.8);
$adeccocLightGray: #c8c7c7;
$adeccoCharcoal: #191919;
$adeccodGrayLight: #C7CED6;
$adeccoShadow: rgba(199, 206, 214, 0.8);
$pastelCerise: rgba(159, 41, 68, 0.1);
$pastelPlum: #e4d5d9;
$pastelOrange: #f9dccd;
$pastelYellow: #fceecc;
$pastelPurple: #efd1ee;
$pastelTeal: #ccf0ea;
$pastelGreen: #daefd4;
$pastelBlue: #cceef9;
$gray: rgba(150, 140, 131, 0.2);
$greenLabel: #007261;

$adeccoPrimaryBlack: #273446;
$primaryBrandRed: #DA291C;

//tag label text color
$labelBlue: #00607f;
$labelTeal: #007261;
$labelOrange: #7e2c00;
$labelYellow: #8b6100;
$labelPurple: #6b006a;
$labelPlum: #5a0015;
$labelGreen: #167500;
$labelCerise: #7f0023;

$redGradient: linear-gradient(273deg, $adeccoRed 0%, $adeccoRed 70%, $adeccoPlum 100%);
$button-shadow: 0 0 0 1px #f1f1f1;
// $redGradient2: linear-gradient(273deg, $adeccoRed 100%, $adeccoRed 30%, $adeccoPlum);
// $redGradient: linear-gradient(273deg, #f64e42 0%, $adeccoPlum 100%);

$spaceSmall: 8px;
$spaceMedium: 16px;
$spaceLarge1: 32px;
$spaceLarge2: 48px;
$spaceHuge1: 56px;
$spaceHuge2: 64px;

$borderRadiusSmall: 5px;
$borderRadiusMedium: 10px;
$borderRadiusLarge: 20px;

$boxShadowL3: 0px 4px 4px #dbdbdb;
$boxShadowL2: 0px 2px 4px #dbdbdb;
$boxShadowL1: 0px 1px 4px #dbdbdb;
$borderRadiusRound: 50%;

// Used in material theme
:export {
  adeccoTwilight: $adeccoTwilight;
  black: $black;
  adeccoBlue: $adeccoBlue;
  white: $white;
  adeccoPink: $adeccoPink;
  adeccoCerise: $adeccoCerise;
  dust: $dust;
  adeccoRed: $adeccoRed;
  adeccoRoyal: $adeccoRoyal;
  adeccoGray: $adeccoGray;
  adeccoGray10: $adeccoGrey10;
  adeccoGray20: $adeccoGrey20;
  adeccoGray40: $adeccoGrey40;
  adeccoGray60: $adeccoGrey60;
  adeccoGray80: $adeccoGrey80;
  adeccoIce: $adeccoIce;
  adeccoPurple: $adeccoPurple;
  adeccodGrayLight: $adeccodGrayLight;
  adeccoDarkRed: $adeccoDarkRed;
  adeccoSecondaryGray: $adeccoSecondaryGray;
  adeccoSecondaryGrayLight1: $adeccoSecondaryGrayLight1;
  adeccoPrimaryBlack: $adeccoPrimaryBlack;
  primaryBrandRed: $primaryBrandRed;
}
