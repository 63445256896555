@import './variables';

.journey {
  color: $black;

  height: 100%;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sticky {
      position: fixed;
      top: 80vh;
      width: 100%;
      button {
        margin-left: auto;
        margin-right: auto;
        display: block;
        background: $adeccoRose;
        color: white;
        border: none;
        padding-top: $spaceMedium;
        padding-bottom: $spaceMedium;
        width: 80%;
        font-size: 20px;
        border-radius: $borderRadiusMedium;
      }
    }

    .descriptionText {
      max-width: min-content;
      min-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }

    .center {
      text-align: center;
    }
    .bold {
      font-weight: bold;
    }
    .largeFont {
      font-size: 20px;
    }

    .midFont {
      font-size: 16px;
    }

    .select {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 8px;
      border-color: $gray;
      color: $gray;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: $spaceMedium;

      .arrow {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .bottomBoard {
      background: white;
      min-height: 30%;

      .doneButton {
        margin: $spaceSmall;
        font-weight: 500;
      }
    }
  }
  .active {
    background: #c4c4c4;
  }
}