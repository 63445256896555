@import 'variables.scss';

.client-chip-day {
  width: 216px;
  height: 80px;
  border-radius: 10px;
  border: solid 2px $adeccoIce;
  &--active {
    background-color: $pastelYellow !important;
    color: $adeccoTwilight !important;
    border: solid 2px $adeccoMarigold;
  }

  &__label-text {
    font-size: 14px;
    font-style: normal;
    font-family: Merriweather, sans-serif;
    font-weight: 900;
    line-height: 1.29;
    font-stretch: normal;
    letter-spacing: 0.7px;
    color: $adeccoRoyal;
  }

  &__label-text-active {
    font-size: 14px;
    font-style: normal;
    font-family: Merriweather, sans-serif;
    font-weight: 900;
    line-height: 1.29;
    font-stretch: normal;
    letter-spacing: 0.7px;
    color: $adeccoTwilight;
  }

  &__icon-box {
    width: 48px;
    height: 48px;
    margin: 0 16px 0 0;
    padding: 14px;
    border-radius: 24px;
    background-color: $adeccoIce;
  }

  &__icon-box-active {
    width: 48px;
    height: 48px;
    margin: 0 16px 0 0;
    padding: 14px;
    border-radius: 24px;
    background-color: $white;
  }

  .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
