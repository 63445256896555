.create-shift {
  &__item {
    margin-bottom: 32px;
  }
  &__label {
    display: block;
    line-height: 23px;
    font-size: 21px;
    margin-bottom: 25px;
  }
}
