.messages {
  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: $spaceMedium;
  }

  &-container {
    padding: $spaceMedium 0;
  }

  .new-messages {
    border-top: 2px solid $adeccoRed;
  }

  .old-messages {
    border-top: 2px solid $adeccodGrayLight;
  }

  &-title {
    font-family: $nunito !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 25px !important;
  }

  &-button {
    border: 2px solid $adeccoGray2;
    border-radius: $borderRadiusSmall;
    padding: $spaceMedium 0;
  }

  &-card {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border: 1px solid $primaryDark;
    border-radius: $borderRadiusSmall;
    padding: $spaceSmall;
    margin: $spaceSmall 0;

    &__red-dot {
      width: 8px;
      height: 8px;
      background-color: $adeccoRed;
      border-radius: 50%;
      margin-top: 4px;
    }

    &__no-dot {
      width: 8px;
      height: 8px;
      background-color: transparent;
      border-radius: 50%;
      margin-top: 4px;
    }

    &__sender {
      font-size: 14px !important;
      font-weight: $bold !important;
      font-family: $nunito !important;
    }

    &__title {
      font-size: 14px !important;
      font-family: $nunito !important;
    }

    &__text {
      font-size: 12px !important;
      font-family: $nunito !important;
    }
  }
}
