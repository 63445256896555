@use 'src/styles/variables' as *;

.button {
  cursor: pointer;
}

.content {
  background-color: $backgrounds02;
  padding: 16px;
}
