.feature-mail-overview {
  //   min-height: calc(100vh - 80px);
  &__container {
    min-height: calc(100vh - 80px);
  }
  &__left-side {
    box-shadow: 0px 4px 16px rgba(28, 48, 75, 0.1);
    padding: 32px;
    position: relative;
    @include for_breakpoint(full-hd) {
      width: 328px;
      flex-basis: auto;
    }
  }
  &__right-side {
    padding: 32px;
    position: relative;
    @include for_breakpoint(full-hd) {
      padding-left: 120px;
      flex-basis: calc(100% - 328px - 80px);
      max-width: 100%;
    }
  }
  &__loader {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__item {
    border: 1px solid #ccccd3;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
  }

  &__tabs-container {
    .tab-list {
      width: 100%;
      justify-content: space-around;
      gap: 16px;
      & > button {
        white-space: unset;
        word-break: break-word;
        hyphens: auto;
      }

      .dropdown {
        .dropdown-menu {
          width: auto !important;
          .dropdown-item.tab-list-item {
            width: 100%;
          }
        }
      }
    }

    .tab-content {
      height: calc(100vh - 249px);
      overflow: scroll;
    }
  }

  &__send-button-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px 16px 16px 16px;
    border-top: 1px solid #ebebee;
    background-color: white;
  }

  &__send-button {
    width: 100%;
  }
}
