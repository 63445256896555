@import 'variables';

.date-time-input {
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .MuiOutlinedInput-input {
    font-size: 21px;
    font-family: Merriweather, sans-serif;
    padding-left: 0;
    font-weight: 900;
    color: $adeccoRoyal;
    &::placeholder {
      font-size: 24px;
      font-weight: 900;
      font-family: Merriweather, sans-serif;
      color: $adeccoDawn !important;
    }
  }
}
