@import 'variables';

.documents-and-data-upload-approve-modal {
  &__main {
    padding: 40px;
    display: flex;
    gap: 20px;
    background: $adeccoSecondaryGrayLight2;
  }

  &__wrapper {
    background: $white;
    border-radius: 8px;
  }

  &__dialog-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    & > .MuiTypography-root {
      color: $white !important;
    }
    min-width: 200px;
    min-height: 30px;
  }

  &__actions-title {
    font-family: HouschkaHead;
    font-size: 24px;
    line-height: 28px;
    color: $primaryDark;
    border-bottom: 2px solid $adeccoSecondaryGrayLight2;
    padding-bottom: 13px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions-caption {
    font-family: HouschkaHead;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
  }

  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: auto;

    & > img {
      max-width: 100%;
      height: auto;
      cursor: zoom-in;
    }
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 25px;
    color: white;
    justify-content: center;
    align-items: center;
    border-left: 1px solid white;
    padding-left: 17px;
    height: 60px;
    &-caption {
      color: $white !important;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__close-fullscreen {
    cursor: pointer;
  }

  .MuiPaper-root.MuiDialog-paper {
    padding: 0;
    border-radius: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    overflow: hidden;
    margin: $spaceLarge1;
  }
  .MuiDialogTitle-root {
    background-color: $primaryBrandRed;
  }

  &__action-container {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
  }

  &__action-button {
    width: 100%;
    .MuiButton-label {
      text-transform: none;
      font-size: 16px;
    }
    &--red {
      width: 100%;
      color: $primaryBrandRed;
      border: 1px solid $primaryBrandRed;

      .MuiButton-label {
        text-transform: none;
        font-size: 16px;
      }
    }

    &--black {
      width: 100%;
      // color: $primaryBrandRed;
      border: 2px solid $primaryDark;

      .MuiButton-label {
        text-transform: none;
        font-size: 16px;
      }
    }
  }

  &__status-completed {
    color: $adeccoGreen2;
  }

  &__status-rejected {
    color: $primaryBrandRed;
  }
}
