@import '../../../../styles/ootheme/variables';

:global(.tag-ds) {
  .notification {
    width: 392px !important;
    transform: unset !important;
    left: unset !important;
    right: 112px !important;
  }
}
