.select-date {
  input[type='date'] {
    position: relative;
    padding: 16px;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

  input[type='date']:before {
    background: url('https://upload.wikimedia.org/wikipedia/commons/9/9d/Arrow-down.svg');
    display: block;
    width: 15px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 6px;
  }
}
