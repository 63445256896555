@import '../../../../../styles/mixins';

.candidateDocumentsUploadPage {
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  grid-column: 1/8;
  @include for_breakpoint(full-hd) {
    max-width: 640px;
    width: 100%;
  }
}

.emptyFieldsAlert {
  color: red;
  font-size: 1rem;
  margin-bottom: 18px;
}