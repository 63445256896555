.container {
  width: 100%;

  & > div {
    background-color: white;
    width: 100%;
    margin-bottom: 16px;
  }

  .content-switcher-item-content {
    display: none;
  }
}
