@import '../theme1/variables';

.feature-onboarding-home {
  height: 100%;

  &__background {
    width: 100%;
    height: auto;
  }

  &__actions {
    & > button {
      margin-bottom: $spaceSmall;
    }
  }
}
