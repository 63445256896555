@use 'src/styles/variables' as *;

.container {
  background-color: $backgrounds02;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.main-section {
  width: 436px;
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 48px;
  overflow: auto;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  cursor: pointer;
}
