@use 'src/styles/variables' as *;

.container {
  display: flex;
  height: calc(100vh - 80px);
}

.stepsSelector {
  margin-right: 32px;
  height: 100%;
}

.pagePreview {
  height: calc(100% - 32px);
}

.renderList {
  max-width: 436px;
  overflow: visible;
  margin: 32px 32px 0;
}

.configurationActions {
  position: fixed;
  right: 112px;
  bottom: 24px;
}

.pagePreview {
  &__container {
    margin-top: 32px;
    margin-bottom: 24px;
    height: 100%;
  }
  &__grayArea {
    background-color: $backgrounds02;
    border-radius: 8px;
    height: calc(100% - 98px);
  }
  &__title {
    border-bottom: 1px solid $bordersDividers01;
    display: block;
    padding: 12px 0;
    text-align: center;
  }
  &__formContainer {
    background-color: white;
    padding: 32px 24px 16px;
    flex: 1;
    border-radius: 24px;
    margin: 20px 40px;
    width: 356px;
    box-sizing: content-box;
    height: calc(100% - 164px);
    overflow: auto;
    &::-webkit-scrollbar {
      display: initial !important;
    }
  }
}

.controlDetails {
  padding: 32px 40px;
  flex: 1;
  height: 100%;
  background-color: $backgrounds02;
  z-index: 2;
  overflow: auto;
  width: 556px;
  &__heading {
    margin-bottom: 28px;
  }
  &__name {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  &__inputs {
    width: 358px;
  }
}

.controlList {
  height: calc(100% - 82px);
  overflow: scroll;
  width: 436px;
  max-width: 100%;
  &::-webkit-scrollbar {
    display: initial !important;
  }
  &__configuratioNameContainer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__pageName {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }
  &__previewModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
    border-radius: 10px;
    padding: 16px;
    height: 70vh;
    overflow: scroll;
  }
}

.controlListItem {
  border: 1px solid $bordersDividers01;

  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 100ms ease;
  cursor: grab;
  &:hover {
    box-shadow: 4px 2px 16px 0px rgba(28, 48, 75, 0.08);
  }
  &__document {
    position: relative;
    cursor: initial;
    &:hover {
      transform: none;
    }
  }

  &__label {
    margin-left: 8px;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 16px;
    word-break: break-all;
  }
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 0 0 8px;
    span {
      font-size: 16px;
    }
  }
}

.stepsSelector {
  &__container {
    min-height: 100%;
    height: 70vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: initial !important;
    }
  }
  &__pageActive {
    color: $textInteractiveLink_on_lightPressed;
    font-weight: bold !important;
  }
}

.addNewControlList {
  padding: 8px 16px;
  &:hover {
    background-color: $backgrounds02;
  }
}

.pageName {
  color: $text02;
  margin-bottom: 16px !important;
  display: flex;
  align-items: center;
}

.versionSelect {
  margin-bottom: 24px;
}
