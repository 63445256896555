.feature-onboarding {
  @import 'app-vertical-stepper-ver1';
}
.onboarding {
  &__header-v1 {
    background-image: linear-gradient(to bottom, #d72620, #d32028, #ce1a2e, #c91534, #c31139);
    .MuiTypography-colorSecondary {
      color: #fefefe;
    }
    .MuiSvgIcon-colorSecondary {
      color: #fefefe;
    }
  }
  &__header-v2 {
    background-color: #f7f7f7;
    .MuiTypography-colorSecondary {
      color: #010101;
    }
    .MuiSvgIcon-colorSecondary {
      color: #968c83;
    }
  }

  &__header__keyboard-backspace-box {
    svg {
      font-size: 1.5rem;
    }
  }
  &__header__keyboard-backspace-icon {
    transform: rotate(180deg);
  }

  &__header__dialog {
    .MuiDialog-paper {
      margin: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
    }
  }
  &__dialog__wrapper {
    padding: 2.5rem;
    text-align: center;
  }
  &__header__dialog__wrapper__button {
    border-radius: 0.5rem;
    border: 0.0625rem solid #f7f7f7;
    background-color: #f7f7f7;
    margin-top: 2.5rem;
    height: 3rem;
    box-shadow: 0 0.3125rem 0.3125rem 0 rgba(0, 0, 0, 0.23);
  }
  &__header__help-btn {
    padding: 1.375rem;
    svg {
      font-size: 1.5rem;
    }
  }

  &__template {
    background: #fff;
  }

  &__template__close-icon {
    margin: 1.5rem;
    color: $primaryBrandRed;
    font-size: 2rem;
  }

  &__template__content-box {
    border-radius: 2rem 2rem 0 0;
    background: #fff;
    padding: 1rem 1rem 7rem;
  }

  &__page-builder__paragraph {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #06051e;
    font-weight: normal;
  }
  &__page-builder__disclaimer {
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0;
    display: block;
  }
  &__page-builder__unordered-list {
    padding-left: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    li {
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #06051e;
      margin-bottom: 1.125rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__page-builder__ordered-list {
    padding-left: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    li {
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #06051e;
      margin-bottom: 0.5rem;
      font-weight: normal;
    }
  }
  &__page-builder__unordered-list-with-details {
    padding-left: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    li {
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #06051e;
      font-weight: bold;
      margin-bottom: 4px;
    }
    div {
      margin-bottom: 4px;
    }
  }
  &__page-builder__group-details {
    margin-bottom: 1.125rem;
  }
  &__employee-handbook__box {
    a {
      font-size: 0.875rem;
    }
    .active-page__animated {
      position: relative;
      @keyframes transitionAnimation {
        from {
          transform: translateX(100vw);
        }
        to {
          transform: translateX(0);
        }
      }
      animation: 0.3s cubic-bezier(0.4, 0, 0.2, 1) transitionAnimation;
    }
  }
  &__employee-handbook__grid {
    margin-bottom: 1.125rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__progress-circular-button__container {
    & > button:first-of-type {
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      margin-bottom: 1rem;
      padding: 0;
      svg {
        path {
          fill: white;
        }
      }
    }

    .disabled-color {
      color: #8c8c94;
    }
  }

  &__text-paragraph__list-item {
    margin-bottom: 1rem;
    list-style: none;
  }
  &__text-paragraph__list-item-subsection {
    list-style: none;
  }
  &__text-paragraph__box {
    font-size: 0.875rem;
    font-weight: bold;
  }
  &__text-paragraph__text {
    margin: 0;
  }

  &__sign-electronically__icon-button-right {
    right: 1.25rem;
  }
  &__sign-electronically__icon-button-left {
    right: 1.25rem;
  }
  &__sign-electronically__border-container {
    padding: 2.625rem 0.9375rem 2.625rem 0.9375rem;
    margin-bottom: 1.25rem;
  }
  &__documents-page__container {
    background: #f3f3f4;
    position: relative;
  }
  &__documents-page__box {
    background-color: white;
    height: 100%;
    overflow: auto;
    width: 100%;
    padding: 1.25rem 1.25rem 10rem;
    border-radius: 2rem 2rem 0 0;
  }
  &__documents-page__card-box {
    border: 0.125rem solid #efefef;
    border-radius: 0.75rem;
    margin: 0.9375rem 0;
  }
  &__documents-page__card-typography {
    font-weight: bold;
    color: black;
    font-size: 1.125rem;
  }
  &__documents-page__grid {
    margin-top: 1rem;
  }
  &__custom-btn {
    border-radius: 0.5rem;
    border: none;
    background-color: #f7f7f7;
    padding: 0.875rem 1.2rem 1rem;
    box-shadow: 0 0.3125rem 0.3125rem 0 #968c83;
    width: 9rem;
    opacity: 1;
    margin-top: 2.5rem;
    :first-of-type span {
      font-size: 1rem;
    }
  }
  &__custom-btn:hover {
    background-color: #f7f7f7;
    box-shadow: 0 0.3125rem 0.3125rem 0 #968c83;
  }
  &__get-started-intro-page__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 5;
    color: black;
  }
  &__get-started-intro-page__close-icon {
    margin: 1.5rem;
    color: $primaryBrandRed;
    font-size: 2rem;
  }
  &__get-started-intro-page__btn {
    padding: 15px 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - 3rem);
    margin: 1.5rem auto;
  }
  &__get-started-intro-page__grid {
    margin-bottom: 1.5rem;
    align-items: center;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__get-started-signature_page__header {
    background-color: #db3736;
    color: white;
    padding: 1.1rem 1.3rem;
  }
  &__get-started-signature_page__close-icon {
    font-size: 2.15rem;
    color: white;
  }
  &__progress-dialog-component__wrapper {
    .MuiDialog-paper {
      border-radius: 12pt 12pt 0 0;
      margin: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
      padding: 1.5rem;
    }
  }
  &__progress-dialog-component__button {
    width: 9rem;
    margin-bottom: 1rem;
    padding: 0.875rem 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__vertical-stepper__time-left {
    z-index: 1;
    svg {
      circle {
        stroke-linecap: round;
        stroke-width: 3;
      }
    }
  }
  &__vertical-stepper__time-left-background {
    color: rgba(65, 65, 80, 0.3);
    position: absolute;
    z-index: 0;
    top: 1rem;
    left: 11rem;
    & .MuiCircularProgress-circle {
      stroke-dasharray: 0.1, 4.55 !important;
      stroke-linecap: round;
      stroke-width: 3;
    }
  }
  &__vertical-stepper__wrapper {
    & .MuiStepConnector-line {
      border-left-style: unset;
      border-right-style: solid;
      border-right-width: 1px;
      height: 1.5rem;
    }
    & .MuiStepConnector-vertical {
      padding: 0;
      margin: 0 1.81rem 0 0;
      &.Mui-disabled {
        display: none;
      }
      &.MuiStepConnector-active .MuiStepConnector-line {
        border: unset;
        display: none;
      }
    }
  }
  &__vertical-stepper__step {
    position: relative;
    min-height: 5rem;
    margin-bottom: 1rem;
    background-color: #f4f3f2;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    &.active-step {
      border: 2px solid rgb(239, 239, 239);
      background-color: white;
      cursor: pointer;
      .completed-label {
        margin-right: 1.87rem;
      }
    }
    &.MuiStep-completed {
      background-color: white;
      margin-bottom: 0;
      .completed-label {
        margin-left: 0;
        margin-right: 1.87rem;
      }
      .MuiStepIcon-root {
        display: block;
        color: black;
      }
    }
    & .MuiStepLabel-iconContainer {
      position: absolute;
      right: 1rem;
      padding-right: 0;
    }
    .MuiStepLabel-vertical {
      width: 100%;
    }
    .MuiStepIcon-root {
      display: none;
    }
    .MuiStepLabel-label {
      display: flex;
      align-items: center;
    }
    &__i9__mobile-stepper {
      margin-top: 0.5rem;
      .MuiMobileStepper-dotActive {
        border-radius: 0.5rem;
        background-color: black;
        width: 1.5rem;
      }
    }
  }
  &__disappearing-button {
    font-size: 1rem;
    background-color: #f7f7f7;
    padding: 0.875rem 1.2rem 1rem;
    box-shadow: 0 0.3125rem 0.3125rem 0 #968c83;
  }
  &__disappearing-button:hover {
    box-shadow: 0 0.3125rem 0.3125rem 0 #968c83;
    background-color: #f7f7f7;
  }
  &__ca-consumer-disclosure-page__button {
    background-color: #f7f7f7;
    padding: 0.875rem 1.2rem 1rem;
    box-shadow: 0 0.3125rem 0.3125rem 0 #968c83;
    width: auto;
    font-size: 1rem;
    font-weight: bold;
  }
  &__ca-consumer-disclosure-page__button:hover {
    background-color: #f7f7f7;
    box-shadow: 0 0.3125rem 0.3125rem 0 #968c83;
  }
}
