@import 'variables';

.personal-details {
  width: 100%;
  &__item {
    background-color: $adeccoSecondaryGrayLight2;
    padding: $spaceMedium;
  }

  &__other-item {
    background-color: $adeccoSecondaryGrayLight3;
    padding: $spaceMedium;
  }

  &-form {
    &__item {
      margin-bottom: $spaceSmall;
    }
    &__switch-container {
      display: flex;
    }
  }
  & .MuiCheckbox-colorSecondary.Mui-checked {
    color: rgba(0, 0, 0, 0.54);
  }
  & .MuiRadio-colorSecondary.Mui-checked {
    color: rgba(0, 0, 0, 0.54);
  }
  & .personal-details-payment-check {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & svg {
      color: $adeccoYellow;
      margin-right: 15px;
    }
    & p {
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}
