@import 'variables';

.client-chip-squared {
  height: 120px;
  border-radius: 8px;
  color: $adeccoPassive;
  width: 96px;
  &--active {
    color: white;
  }
}
